import { EMPTY } from "rxjs";

// BR
export const locale = {
  lang: "br",
  data: {
    USER_FEEDBACK_DROPDOWN: {
      SHARE_YOUR_FEEDBACK: "Compartilhe seu Feedback",
      RATING: "Avaliação",
      MESSAGE: "Mensagem",
      SEND: "Enviar",
      INVALID_FORM_MESSAGE:
        "Por favor, selecione uma nota e escreva uma mensagem antes de enviar o feedback.",
      THANK_YOU_FOR_YOUR_FEEDBACK_TITLE: "Muito obrigado pelo seu feedback!",
      THANK_YOU_FOR_YOUR_FEEDBACK_TEXT:
        "Agradecemos sinceramente por compartilhar sua opinião conosco. Seu feedback é extremamente valioso e nos ajuda a continuar aprimorando nossa plataforma.",
    },
    USER_DROPDOWN: {
      NEW_GROUP: "Novo grupo",
      CONTACTS: "Contatos",
      GROUPS: "Grupos",
      CALLS: "Chamadas",
      SETTINGS: "Configurações",
      HELP: "Ajuda",
      PRIVACY: "Privacidade",
    },
    ERROR: {
      OOPS: "OOPS! Algo de errado aconteceu aqui",
    },
    USER_NOTIFICATION: {
      IMPORT_IN_PROGRESS: "Importação em progresso",
      IMPORT_COMPLETED: "Importação concluída",
      FAILED_IMPORT: "Falha na importação",
      NEW_NOTIFICATIONS: "Novas",
      ALERTS: "Alertas",
      EVENTS: "Eventos",
      ALL_CAUGHT_UP: "Tudo em dia!",
      NO_NEW_NOTIFICATIONS: "Não há novas notificações.",
    },
    KEYWORDS: {
      PREVIOUS: "Anterior",
      NEXT: "Próximo",
      CLOSE: "Fechar",
      CONFIRM: "Confirmar",
    },
    TRANSLATOR: {
      SELECT: "Selecione sua língua",
    },
    PAGINATOR: {
      LOADING: "Carregando...",
      SHOWING: "Mostrando fileira",
      TO: "até",
      OF: "de",
    },
    ORDERS: {
      SELECTED_AREA: "Área selecionada",
      RETENTION_END_PERIOD: "Período final de retenção",
      TOTAL_LEADS: "Total de leads",
      LEADS_DETAILS: "Detalhes do leads",
      IN_PROGRESS: "Em progresso",
      ERROR: "Erro",
      SUCCESS: "Sucesso",
      PENDING: "Pendente",
      CANCELED: "Cancelado",
      TRIM_EXPORTER: "Trim",
      LEADS: "Leads",
      ORDER_CREATED: "Data de criação",
      TRIM_DETAILS: "Detalhes do Trim",
      DISPLAY_NAME: "Nome",
      IS_OUTDATED: " Está desatualizado",
      MEDIA_FILE_URL: "Link para o arquivo",
      UPDATE_DATE: "Data de atualização",
      LAYER_NAME: "Nome da camada",
      PRICE: "Preço",
      FALSE: "Não",
      TRUE: "Sim",
    },
    PAYMENT: {
      COUPON_HAS_ALREADY_BEEN_USED: "Este cupom já foi utilizado",
      INSERT_COUPON: "Insira o cupom aqui",
      START_TRIAL_PERIOD: "Iniciar período de teste",
      PHONE_NUMBER: "Número de telefone",
      OK: "OK",
      CPF: "CPF",
      CPF_CNPJ: "CPF/CNPJ",
      INVALID_CPF: "CPF inválido",
      SIGNED_PLAN: "Plano assinado",
      CONTINUE: "Confirmar",
      NOT_DEFINED: "Não definido",
      TRIAL: "Período de teste",
      FAILED: "Falhou",
      PROCESSED: "Processado",
      REQUESTED: "Requisitado",
      DATE_FORMAT: "MM/AAAA",
      GENERATE_BANKSLIP: "Gerar boleto",
      SELECT_AN_ADDRESS: "Por favor, selecione um endereço",
      DISCOUNT_COUPON: "Cupom de desconto",
      DISCOUNT_APPLY: "Aplicar desconto",
      COUPON_INVALID: "Cupom inválido",
      COUPON_APPLIED: "Cupom aplicado",
      CARD_MONTH: "Mês",
      CARD_YEAR: "Ano",
      INFORMATION_ABOUT_CVV:
        "Corresponde aos três números que aparecem no verso do cartão",
      CONFIRM: "Confirmar pagamento",
      CARD_CVV: "CVC",
      CARD_EXPIRATION_DATE: "Data de vencimento",
      CARD_NUMBER: "Número do cartão",
      CARD_OWNER_NAME: "Nome no cartão",
      BANK_SLIP: "Boleto Bancário",
      DEBIT_CARD: "Cartão de débito",
      CREDIT_CARD: "Cartão de crédito",
      YOUR_PLAN: "Seu plano:",
    },
    MENU: {
      CLEAR_ALL: "Limpar todas",
      YOU_HAVE: "Você tem",
      DAYS_OF_FREE_TRIAL: "dias de teste grátis.",
      BACKOFFICE: "Backoffice",
      DASHBOARD: "Painel de controle",
      EBI: "e-BI",
      LEADS: "Leads",
      ECOMAP: "EcoMap",
      ECNPJ: "e-CNPJ",
      EMAP: "e-Map",
      FEED: "Feed",
      TUTORIALS: "Tutoriais",
      WORKSPACE: "Workspace",
      GED: "Documentos",
      QUICK_SEARCH: "Pesquisar",
      NOTIFICATION: "Notificações",
      WELCOME_MESSAGE: "Olá,",
      USER: {
        MESSAGE: "Mensagens",
        MY_PROFILE: "Meu perfil",
        DESCRIPTION_PROFILE: "Configurações da conta e mais",
        UPGRADE_PROFILE: "Atualizar",
        MY_MESSAGES: "Minhas mensagens",
        DESCRIPTION_MESSAGES: "Caixa de entrada e tarefas",
        MY_ACTIVITIES: "Minhas atividades",
        DESCRIPTION_ACTIVITIES: "Histórico e notificações",
        MY_TASKS: "Minhas tarefas",
        DESCRIPTION_TASKS: "Últimas tarefas e projetos",
        SIGN_OUT: "Sair",
        UPGRADE_PLAN: "Atualizar plano",
      },
      USER_PROFILE: {
        CHAT: "Bate-Papo",
        FOLLOW: "Seguir",
        EMAIL: "Email",
        PHONE: "Telefone",
        PROFILE_OVERVIEW: "Visão geral do perfil",
        PERSONAL_INFORMATION: "Informação pessoal",
        MY_ORGANIZATIONS: "Minhas Organizações",
        CHANGE_PASSWORD: "Mudar senha",
        EMAIL_SETTINGS: "Definições de Email",
        SAVED_CREDIT_CARD: "Cartão de crédito salvo",
        ADDRESS: "Endereços do usuário",
        LOGIN_ACTIVITY: "Atividade de login",
        DESCRIPTION_ACTIVITY: "Por data e endereço IP",
        PERSONAL_INFO: "Informações pessoais",
        UPDATE_PERSONAL_INFO: "Atualize suas informações pessoais",
        SAVE_CHANGES: "Salvar mudanças",
        CANCEL: "Cancelar",
        CUSTOMER_INFO: "Informação do cliente",
        AVATAR: "Avatar",
        AVATAR_TIP: "Tipos de arquivo permitidos: png, jpg, jpeg.",
        REAL_NAME: "Nome",
        LAST_NAME: "Sobrenome",
        CONTACT_INFO: "Informações de contato",
        CONTACT_PHONE: "Telefone de contato",
        CONTACT_PHONE_TIP:
          "Nunca compartilharemos seu telefone com mais ninguém",
        EMAIL_ADDRESS: "Endereço de E-mail",

        CHANGE_PASSWORDS: "Mudar senha",
        CHANGE_YOUR_ACCOUNT_PASSWORD: "Altere a senha da sua conta",
        CURRENT_PASSWORD: "Senha atual",
        CURRENT_PASSWORD_WAS_ENTERED_CORRECT:
          "A senha atual foi digitada corretamente",
        CURRENT_PASSWORD_IS_REQUIRED: "A senha atual é obrigatória",
        NEW_PASSWORD: "Nova senha",
        VERIFY_PASSWORD: "Verifique a senha",
        PASSWORD_CONFIRMATION_WAS_ENTERED_CORRECT:
          "A confirmação da senha foi digitada corretamente",
        PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH:
          "'Senha' e 'Confirmar senha' não correspondem",

        EMAIL_SETTINGS_2: "Definições de Email",
        EMAIL_SETTINGS_TIP: "Atualize suas definições de Email",
        SETUP_EMAIL_NOTIFICATION: "Configure suas notificações de Email",
        EMAIL_NOTIFICATION: "Notificações de Email",
        SEND_COPY_TO_PERSONAL_EMAIL: "Enviar copia para seu Email pessoal",
        ACTIVITY_RELATED_EMAILS: "Email relacionado a atividade",
        WHEN_TO_EMAIL: "Quando enviar Email",
        YOU_HAVE_NEW_NOTIFICATIONS: "Você tiver notificações",
        YOU_ARE_SENT_A_DIRECT_MESSAGE: "Você enviou uma mensagem direta",
        SOMEONE_ADDS_YOU_AS_A_CONNECTION:
          "Alguém lhe adicionou como uma conexão",
        WHEN_TO_ESCALATE_EMAIL: "Quando escalar emails",
        UPON_NEW_ORDER: "Por emails novos",
        NEW_MEMBERSHIP_APPROVAL: "Aprovação de um novo membro",
        MEMBER_REGISTRATION: "Registro de membro",

        UPDATES_FROM_KEENTHEMES: "Atualizações de keenthemes",
        EMAIL_YOU_WITH: "Enviar email com",
        NEWS_ABOUT_KEENTHEMES_PRODUCTS_AND_FEATURE_UPDATES:
          "Notícias sobre produtos Keenthemes e atualizações de recursos",
        TIPS_ON_GETTING_MORE_OUT_OF_KEEN:
          "Dicas para tirar mais proveito do Keen",
        THINGS_YOU_MISSED_SINCE_YOU_LAST_LOGGED_INTO_KEEN:
          "Coisas que você perdeu desde o último login no Keen",
        NEWS_ABOUT_METRONIC_ON_PARTNER_PRODUCTS_AND_OTHER_SERVICES:
          "Notícias sobre a metronic em produtos de parceiros e outros serviços",
        TIPS_ON_METRONIC_BUSINESS_PRODUCTS:
          "Dicas sobre produtos empresariais Metronic",

        USER_ADDRESS: "Endereço do usuário",
        NEW_ADDRESS: "Novo endereço",
        ADDRESS_1: "Endereço 1",
        PRIMARY: "Primário",
        DELETE: "Deletar",
        EDIT: "Editar",

        CREDIT_CARDS_LIST: "Lista de cartões de crédito",
        NEW_CREDIT_CARD: "Novo cartão de crédito",

        CREDIT_CARD: "Cartão de crédito",
        CARD_MONTH_YEAR: "MM/AAAA",
        CARD_CVV: "CVV",
        CARD_EXPIRATION_DATE: "Data de vencimento",
        CARD_NUMBER: "Número do cartão",
        CARD_NAME: "Nome no cartão",
        SAVE: "Salvar",
        CREATE: "Criar",

        VIEW_ORDER: "Detalhes do pedido",
        NUMBER: "Número",
        DATE: " Data",
        MY_REQUESTS: "Meus Pedidos",
        USER_EMAIL: "Email do usuário",
        ORDER_NUMBER: "Número do pedido",

        NEW_USER_ADDRESS: {
          CREATE: "Criar",
          EDIT: "Editar",
          USER_ADDRESS: "endereço do usuário",
          STREET: "Rua",
          STREET_WAS_ENTERED_CORRECT: "Nome da rua foi adicionado corretamente",
          STREET_IS_REQUIRED: "Nome da rua é obrigatório",
          STREET_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Nome da rua deve ter no minimo 3 símbolos",
          STREET_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Nome da rua deve ter no máximo 100 símbolos",
          NUMBER: "Número",
          NUMBER_WAS_ENTERED_CORRECT: "O número foi adicionado corretamente",
          NUMBER_IS_REQUIRED: "Número é obrigatório",
          NUMBER_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Número deve ter no minimo 3 símbolos",
          NUMBER_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Número deve ter no máximo 100 símbolos",
          COMPLEMENT: "Complemento",
          COMPLEMENT_WAS_ENTERED_CORRECT:
            "Complemento foi adicionado corretamente",
          COMPLEMENT_IS_REQUIRED: "Complemento é obrigatório",
          COMPLEMENT_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Complemento deve ter no minimo 3 símbolos",
          COMPLEMENT_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Complemento deve ter no máximo 100 símbolos",
          NEIGHBORHOOD: "Bairro",
          NEIGHBORHOOD_WAS_ENTERED_CORRECT:
            "Bairro foi adicionado corretamente",
          NEIGHBORHOOD_IS_REQUIRED: "Bairro é obrigatório",
          NEIGHBORHOOD_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Bairro deve ter no minimo 3 símbolos",
          NEIGHBORHOOD_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Bairro deve ter no máximo 100 símbolos",
          POSTAL_CODE: "Código Postal",
          POSTAL_CODE_WAS_ENTERED_CORRECT:
            "Código Postal foi adicionado corretamente",
          POSTAL_CODE_IS_REQUIRED: "Código Postal é obrigatório",
          POSTAL_CODE_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Código Postal deve ter no minimo 3 símbolos",
          POSTAL_CODE_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Código Postal deve ter no máximo 100 símbolos",
          SELECT_STATE: "Selecione o estado",
          SELECT_STATE_TIP: "Por favor selecione o estado",
          SELECT_CITY: "Selecione a cidade",
          SELECT_CITY_TIP: "Por favor selecione a cidade",
          CANCEL: "Cancelar",
          SAVE: "Salvar",
        },
        PAYMENTS_LIST: "Lista de pagamentos",
        PAYMENTS: "Pagamentos",
        VIEW_PAYMENTS: "Visualizar pagamento",
        UNIQUE_PAYMENT_OF: "Pagamento único de",
        BY_MONTH: "por mês",
        SUBSCRIPTION_NAME: "Nome da assinatura",
        STATUS: "Status",
        PRICE: "Preço",
        TYPE: "Tipo",
        CREATED: "Data da criação",
        COUPON_CODE_NAME: "Nome do código do cupom",
        PAID_INSTALMENTS: "Parcelas pagas",
        TOTAL_INSTALMENTS: "Total de parcelas",
        BOLETO_URL: "URL do boleto",
        BOLETO_BAR_CODE: "Linha digitável do boleto",
        CREDIT_CARD_HASH: "Hash do cartão",
        ACTION: "Ação",
        CLOSE: "Fechar",

        OWNER: "Admin",
        MEMBER: "Membro",
        MEMBERS: "Membros",
        NAME: "Nome",
        CNPJ: "CNPJ",
        CORPORATE_NAME: "Nome corporativo",
        TRADING_NAME:"Nome comercial",
        TELEPHONE:"Telefone",
        CELLPHONE:"Celular",
        CONTACT_NAME:"Nome de contato",
        CONTACT_EMAIL:"Email de contato",
        FINANCIAL_EMAIL:"Email financeiro",
        FINANCIAL_OFFICER:"Diretor financeiro",
        ADDITIONAL_INFORMATION:"Informações adicionais",
        ORGANIZATION_DETAILS: "Detalhes da Organização",
        ORGANIZATION_OWNER: "Proprietário da organização",
        INVITE: "Convidar",
        SEND: "Enviar",
        INVITED_EMAIL : "Email convidado",
        ROLE: "Função",
        USER_ORG_DELETE: "Excluir usuário da organização",
        USER_ORG_IS_DELETING: "O usuário está sendo excluido...",
        ARE_YOU_SURE_WANT_TO_DELETE_THIS_USER_ORG:
          "Tem certeza que deseja excluir este usuário?",
        DO_YOU_ACCEPT_TO_BE_PART_OF_THIS_ORGANIZATION: "Você aceita fazer parte desta organização?",
        YOU_HAVE_AN_INVITATION: "Você possui um convite!",
        YOU_HAVE_AN_INVITATION_MESSAGE: "Você possui um convite para uma organização pendente de aceite. Por favor, verifique ",
        YOU_HAVE_AN_INVITATION_MESSAGE_CLICK: "clicando aqui",
      },
    },
    SETTINGS: {
      DESCRIPTION:
        "O construtor de layout serve para ajudar a definir e configurar as especificações de layout de projeto preferidas e visualizá-lo em tempo real. As opções de layout configuradas serão salvas até que você as altere ou redefina. Para usar o construtor de layout, escolha as opções de layout e clique no botão 'Visualizar' para visualizar as mudanças.",
      BUTTON_PREVIEW: "Visualizar",
      BUTTON_SAVE: "Salvar",
      BUTTON_RESET: "Resetar",
      VALUES: {
        FIXED: "Fixado",
        FLUID: "Fluido",
        DEFAULT: "Padrão",
        TAB: "Aba",
        TRANSPARENT: "Transparente",
        SOLID: "Sólido",
        SUBHEADER_1: "Subcabeçalho 1",
        SUBHEADER_POWERBI: "Subcabeçalho Power-Bi",
        SUBHEADER_3: "Subcabeçalho 3",
        SUBHEADER_4: "Subcabeçalho 4",
        SUBHEADER_LEADS: "Subcabeçalho Leads",
        SUBHEADER_6: "Subcabeçalho 6",
      },
      SITE: {
        SITE: "Site",
        ENTER_NAME: "Nome",
        ENTER_NAME_PLACEHOLDER: "Nome",
        LOGO: "Logotipo",
        LOGO_TIP: "São permitidos arquivos do tipo: png, jpg e jpeg.",
      },
      HEADER: {
        HEADER: "Cabeçalho",
        DESKTOP_FIXED_HEADER: "Cabeçalho fixo da área de trabalho",
        DESKTOP_FIXED_HEADER_TIP:
          "Permite cabeçalho fixo para a area de trabalho",
        MOBILE_FIXED_HEADER: "Cabeçalho fixo do celular",
        MOBILE_FIXED_HEADER_TIP: "Permite cabeçalho fixo para celular",
        WIDTH: "Largura do cabeçalho",
        WIDTH_TIP: "Selecione o tipo da largura do cabeçalho",
        DISPLAY_HEADER_MENU: "Exibir menu de cabeçalho",
        DISPLAY_HEADER_MENU_TIP: "Exibe o menu de cabeçalho",
        STATIC_HEADER_MENU: "Exibir menu de cabeçalho estático",
        STATIC_HEADER_MENU_TIP: "Exibe o menu de cabeçalho estático",
        MENU_LAYOUT: "Layout do menu de cabeçalho",
        MENU_LAYOUT_TIP: "Selecione o tipo do Layout do menu do cabeçalho",
        MENU_ARROWS: "Setas do menu de cabeçalho",
        MENU_ARROWS_TIP: "Permite setas para o menu de cabeçalho",
      },
      SUBHEADER: {
        SUBHEADER: "Subcabeçalho",
        DISPLAY_SUBHEADER: "Exibir Subcabeçalho",
        DISPLAY_SUBHEADER_TIP: "Exibe Subcabeçalho",
        FIXED_SUBHEADER: "Subcabeçalho fixo",
        FIXED_SUBHEADER_TIP:
          "Exibir Subcabeçalho fixo. Requer estilo 'Solido' do Subcabeçalho.",
        WIDTH: "Largura",
        WIDTH_TIP: "Selecione o tipo de largura do layout",
        STYLE: "Estilo do Subcabeçalho",
        STYLE_TIP: "Selecione o estilo do Subcabeçalho",
        LAYOUT: "Layout do Subcabeçalho",
        LAYOUT_TIP: "Selecione o layout do Subcabeçalho",
      },
      CONTENT: {
        CONTENT: "Conteúdo",
        WIDTH: "Largura",
        WIDTH_TIP: "Selecione o tipo de largura do layout",
      },
      ASIDE: {
        ASIDE: "Menu Lateral",
        DISPLAY: "Exibir",
        DISPLAY_TIP: "Exibir Menu lateral",
        STATIC_ASIDE_MENU: "Menu lateral estático",
        STATIC_ASIDE_MENU_TIP: "Exibir menu lateral estático",
        FIXED: "Fixo",
        FIXED_TIP: "Definir o layout do menu lateral fixo",
        MINIMIZE: "Minimizar",
        MINIMIZE_TIP: "Permite o menu latera minimizar",
        DEFAULT_MINIMIZE: "Minimizar padrão",
        DEFAULT_MINIMIZE_TIP: "Permite o menu lateral minimizar por padrão",
        SCROLL: "Rolagem",
        SCROLL_TIP: "Permite a rolagem do menu lateral",
        SUBMENU_TOGGLE_DROPDOWN: "Lista suspensa de alternância",
        SUBMENU_TOGGLE_DROPDOWN_TIP:
          "Permite lista suspensa de alternância (Funciona apenas quando 'SCROLL' esta desativado. *Por padrão - o modo é 'acordeão').",
      },
      FOOTER: {
        FOOTER: "Rodapé",
        FIXED_DESKTOP_FOOTER: "Rodapé fixo da área de trabalho",
        FIXED_DESKTOP_FOOTER_TIP: "Define rodapé fixo na área de trabalho.",
        WIDTH: "Largura",
        WIDTH_TIP: "Selecione o tipo de lagura do layout",
      },
      COLOR: {
        COLOR: "Cor",
        WHITE_COLOR: "Cor Branca",
        PRIMARY_COLOR: "Cor Primária",
        SECONDARY_COLOR: "Cor Secundária",
        SUCCESS_COLOR: "Cor de sucesso",
        INFO_COLOR: "Cor de informação",
        WARNING_COLOR: "Cor de aviso",
        DANGER_COLOR: "Cor de perigo",
        LIGHT_COLOR: "Cor clara",
        DARK_COLOR: "Cor escura",
        COLOR_TIP: "Selecione sua cor",
      },
    },
    USERS: {
      USERS_LIST: "Lista de usuários",
      NEW_USER: "Novo usuário",
      FILTER_BY_STATUS: "Filtrar por status",
      SEARCH_IN_ALL_FIELDS: "Pesquisar em todos os campos",
      ALL: "Tudo",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      FILTER: "Filtro",
      BY_STATUS: " por status",
      FILTER_BY_TYPE: "Filtrar por tipo",
      BUSINESS: "Negócio",
      INDIVIDUAL: "Individual",
      BY_TYPE: " por tipo",
      SEARCH: "Pesquisar",
      IN_ALL_FIELDS: "em todos os campos",
      SELECTED_RECORDS_COUNT: "Contagem de registros selecionados:",
      DELETE_ALL: "Apagar tudo",
      FETCH_SELECTED: "Buscar selecionado",
      UPDATE_STATUS: "Atualizar o status",
      USER: "Usuário",
      USERNAME: "Nome de usuário",
      STATUS: "Status",
      ACTIONS: "Ações",
      INACTIVE: "Inativo",
    },
    EDIT_USER: {
      EDIT: "Editar",
      CREATE: "Criar",
      BACK: "Voltar",
      RESET: "Resetar",
      BASIC_INFO: "Informações basicas",
      PROFILE_INFO: "Informações do perfil",
      ADDRESS_INFO: "Informações de endereço",
      ENTER_USERNAME: "Entre com o nome de usuário",
      ENTER_USERNAME_TIP: "Porfavor entre com seu nome de usuário",
      USERNAME_WAS_ENTERED_CORRECT:
        "Nome de usuário foi adicionado corretamente",
      USERNAME_IS_REQUIRED: "Nome de usuário é obrigatório",
      USERNAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Nome de usuário deve ter no minimo 3 símbolos",
      USERNAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Nome de usuário deve ter no máximo 100 símbolos",
      ENTER_CPF_CNPJ: "Entre com CPF/CNPJ",
      ENTER_CPF_CNPJ_TIP: "Porfavor entre com CPF/CNPJ",
      CPF_CNPJ_WAS_ENTERED_CORRECT: "CPF/CNPJ foi adicionado corretamente",
      CPF_CNPJ_IS_REQUIRED: "CPF/CNPJ é obrigatório",
      CPF_CNPJ_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "CPF/CNPJ deve ter no minimo 3 símbolos",
      CPF_CNPJ_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "CPF/CNPJ deve ter no máximo 100 símbolos",
      SELECT_PLAN: "Selecione um plano",
      SELECT_PLAN_TIP: "Porfavor selecione um plano",
      SELECT_ACTIVE: "Selecione ativo",
      ACTIVE: "Ativo",
      INACTIVE: "Inativo",
      SELECT_ACTIVE_TIP: "Por favor selecione ativo",
      SELECT_ENABLED: "Selecione ativado",
      ENABLED: "Ativado",
      DISABLED: "Desativado",
      SELECT_ENABLED_TIP: "Porfavor selecione ativado",
      SELECT_CREDENTIALS_EXPIRED: "Selecione as credenciais expiradas",
      SELECT_CREDENTIALS_EXPIRED_TIP:
        "Porfavor selecione as credenciais expiradas",
      SELECT_EXPIRED: "Selecione expirado",
      SELECT_EXPIRED_TIP: "Porfavor selecione expirado",
      SELECT_LOCKED: "Selecione trancado",
      SELECT_LOCKED_TIP: "Porfavor selecione trancado",
      CANCEL: "Cancelar",
      SAVE: "Salvar",
    },
    USER_PROFILE_EDIT: {
      DATE_OF_BIRTH: "Data de nascimento",
      DATE_OF_BIRTH_WAS_ENTERED_CORRECT:
        "Data de nascimento foi adicionado corretamente",
      DATE_OF_BIRTH_TIP:
        "Porfavor entre com a data de nascimento no formato 'dd/mm/yyyy'",
      SELECT_GENDER: "Selecione o gênero",
      FEMALE: "Feminino",
      MALE: "Masculino",
      SELECT_GENDER_TIP: "Porfavor selecione o gênero",
      ENTER_REAL_NAME: "Entre com nome real",
      ENTER_REAL_NAME_TIP: "Porfavor entre com o nome real",
      ENTER_REAL_NAME_WAS_ENTERED_CORRECT:
        "Nome real foi adicionado corretamente",
      ENTER_REAL_NAME_IS_REQUIRED: "Nome real é obrigatório",
      ENTER_REAL_NAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Nome real deve ter no minimo 3 símbolos",
      ENTER_REAL_NAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Nome real deve ter no máximo 100 símbolos",
      ENTER_EMAIL: "Entre com Email",
      ENTER_EMAIL_TIP: "Porfavor entre com o Email",
      ENTER_EMAIL_WAS_ENTERED_CORRECT: "Email foi adicionado corretamente",
      ENTER_EMAIL_IS_REQUIRED: "Email é obrigatório",
      ENTER_EMAIL_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Email deve ter no minimo 3 símbolos",
      ENTER_EMAIL_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Email deve ter no máximo 100 símbolos",
      ENTER_PHONE_NUMBER: "Entre com o número de telefone",
      ENTER_PHONE_NUMBER_TIP: "Porfavor entre com o número de telefone",
      ENTER_PHONE_NUMBER_WAS_ENTERED_CORRECT:
        "número de telefone foi adicionado corretamente",
      ENTER_PHONE_NUMBER_IS_REQUIRED: "número de telefone é obrigatório",
      ENTER_PHONE_NUMBER_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "número de telefone deve ter no minimo 3 símbolos",
      ENTER_PHONE_NUMBER_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "número de telefone deve ter no máximo 100 símbolos",
      ENTER_SITE: "Entre com site",
      ENTER_SITE_TIP: "Porfavor entre com o site",
      ENTER_SITE_WAS_ENTERED_CORRECT: "Site foi adicionado corretamente",
      ENTER_SITE_IS_REQUIRED: "Site é obrigatório",
      ENTER_SITE_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Site deve ter no minimo 3 símbolos",
      ENTER_SITE_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Site deve ter no máximo 100 símbolos",
      CANCEL: "Cancelar",
      SAVE: "Salvar",
    },
    DELETE_USER: {
      USER_DELETE: "Excluir usuário",
      USER_IS_DELETING: "O usuário está excluindo...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER:
        "Tem certeza de que deseja excluir permanentemente este usuário?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    DELETE_USERS: {
      USERS_DELETE: "Excluir usuários",
      USERS_IS_DELETING: "Os usuários estão sendo excluindo...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS:
        "Tem certeza de que deseja excluir permanentemente os usuários selecionados?",
      CANCEL: "Cancel",
      DELETE: "Deletar",
    },
    FETCH_USERS: {
      FETCH_SELECTED_USERS: "Buscar usuários selecionados",
      USERNAME: "Nome de usuário",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      INACTIVE: "Inativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      FETCH: "Buscar",
    },
    UPDATE_USERS: {
      STATUS_UPDATE_FOR_SELECTED_USERS:
        "Atualização de status para usuários selecionados",
      USERNAME: "Nome de usuário",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      INACTIVE: "Inativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      UPDATE_STATUS: "Atualizar o status",
    },
    AUTH: {
      GENERAL: {
        OR: "Ou",
        SUBMIT_BUTTON: "Enviar",
        NO_ACCOUNT: "Não tem uma conta?",
        SIGNUP_BUTTON: "Registrar",
        FORGOT_BUTTON: "Esqueceu a senha?",
        BACK_BUTTON: "Voltar",
        PRIVACY: "Privacidade",
        LEGAL: "Legal",
        CONTACT: "Contato",
      },
      LOGIN: {
        TITLE: "Login Account",
        BUTTON: "Entrar",
      },
      FORGOT: {
        TITLE: "Esqueceu a senha?",
        DESC: "Entre com seu email para resetar sua senha",
        SUCCESS: "Sua conta foi resetada com sucesso.",
      },
      REGISTER: {
        TITLE: "Registre-se",
        DESC: "Entre com seus dados para criar sua conta",
        SUCCESS: "Sua conta foi criada com sucesso.",
      },
      INPUT: {
        EMAIL: "Email",
        FULLNAME: "Nome Completo",
        PASSWORD: "Senha",
        CONFIRM_PASSWORD: "Confirme a senha",
        USERNAME: "Nome de usuario",
      },
      VALIDATION: {
        INVALID: "{{name}} não é valido",
        REQUIRED: "{{name}} é necessario",
        MIN_LENGTH: "{{name}} tamanho minimo é {{min}}",
        AGREEMENT_REQUIRED: "A aceitação dos termos e condições é obrigatória",
        NOT_FOUND: "O {{name}} solicitado não foi encontrado",
        INVALID_LOGIN: "Os dados do login estão incorretos",
        REQUIRED_FIELD: "Campo obrigatório",
        MIN_LENGTH_FIELD: "Tamanho minimo do campo:",
        MAX_LENGTH_FIELD: "Tamanho maximo do campo:",
        INVALID_FIELD: "Campo inválido",
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: "Selected records count: ",
        ALL: "All",
        SUSPENDED: "Suspended",
        ACTIVE: "Active",
        FILTER: "Filter",
        BY_STATUS: "by Status",
        BY_TYPE: "by Type",
        BUSINESS: "Business",
        INDIVIDUAL: "Individual",
        SEARCH: "Search",
        IN_ALL_FIELDS: "in all fields",
      },
      ECOMMERCE: "eCommerce",
      CUSTOMERS: {
        CUSTOMERS: "Customers",
        CUSTOMERS_LIST: "Customers list",
        NEW_CUSTOMER: "New Customer",
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: "Customer Delete",
          DESCRIPTION: "Are you sure to permanently delete this customer?",
          WAIT_DESCRIPTION: "Customer is deleting...",
          MESSAGE: "Customer has been deleted",
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: "Customers Delete",
          DESCRIPTION: "Are you sure to permanently delete selected customers?",
          WAIT_DESCRIPTION: "Customers are deleting...",
          MESSAGE: "Selected customers have been deleted",
        },
        UPDATE_STATUS: {
          TITLE: "Status has been updated for selected customers",
          MESSAGE: "Selected customers status have successfully been updated",
        },
        EDIT: {
          UPDATE_MESSAGE: "Customer has been updated",
          ADD_MESSAGE: "Customer has been created",
        },
      },
    },
    ASIDE: {
      USER_MANAGEMENT: "Gerenciamento de usuários",
      USERS: "Usuários",
      ROLES: "Papel",
      SITES: "Sites",
      LEVELS: "Níveis",
      PLANS: "Planos",
      USER_MAP: "Mapa do usuário",
      LOGINS: "Logins",
      USER_POWERBI: "e-BI do usuário",
      USER_ACCESS_MANAGEMENT: "Gerenciamento de acesso do usuário",
      COMPANIES: "Empresas",
      USERS_ADDRESS: "Endereço dos usuários",
      USER_PROFILE: "Perfil de usuário",
    },
    USERS_ADDRESS: {
      USERS_ADDRESS_LIST: "Lista de endereços de usuários",
      NEW_USER_ADDRESS: "Novo endereço de usuário",
      FILTER_BY_STATUS: "Filtrar por status",
      ALL: "Tudo",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      FILTER: "Filtro",
      BY_STATUS: " por status",
      FILTER_BY_TYPE: "Filtrar por tipo",
      BUSINESS: "Negócio",
      INDIVIDUAL: "Individual",
      BY_TYPE: " por tipo",
      SEARCH: "Pesquisar",
      IN_ALL_FIELDS: "em todos os campos",
      SELECTED_RECORDS_COUNT: "Contagem de registros selecionados:",
      DELETE_ALL: "Apagar tudo",
      FETCH_SELECTED: "Buscar selecionado",
      UPDATE_STATUS: "Atualizar o status",
      USER: "Usuário",
      STREET: "Rua",
      NUMBER: "Número",
      COMPLEMENT: "Complemento",
      NEIGHBORHOOD: "Bairro",
      POSTAL_CODE: "Código postal",
      STATE: "Estado",
      CITY: "Cidade",
      ACTIONS: "Ações",
    },
    EDIT_USER_ADDRESS: {
      EDIT: "Editar",
      CREATE: "Criar",
      USER_ADDRESS: "endereço do usuário",
      SELECT_USER: "Selecione o usuário",
      PLEASE_SELECT: "Por favor selecione",
      USER: "Usuário",
      STREET: "Estado",
      STREET_WAS_ENTERED_CORRECT: "A rua foi inserida corretamente",
      STREET_IS_REQUIRED: "A rua é obrigatória",
      STREET_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "A rua deve ter pelo menos 3 símbolos",
      STREET_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "A rua deve ter no máximo 100 símbolos",
      NAME: "Nome",
      NAME_WAS_ENTERED_CORRECT: "O nome foi inserido corretamente",
      NAME_IS_REQUIRED: "O nome é obrigatório",
      NAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "O nome deve ter pelo menos 3 símbolos",
      NAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "O nome deve ter no máximo 100 símbolos",
      NUMBER: "Número",
      NUMBER_WAS_ENTERED_CORRECT: "O número foi inserida corretamente",
      NUMBER_IS_REQUIRED: "O número é obrigatório",
      NUMBER_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "O número deve ter pelo menos 3 símbolos",
      NUMBER_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "O número deve ter no máximo 100 símbolos",
      COMPLEMENT: "Complemento",
      COMPLEMENT_WAS_ENTERED_CORRECT: "O complemento foi inserida corretamente",
      COMPLEMENT_IS_REQUIRED: "O complemento é obrigatório",
      COMPLEMENT_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "O complemento deve ter pelo menos 3 símbolos",
      COMPLEMENT_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "O complemento deve ter no máximo 100 símbolos",
      NEIGHBORHOOD: "Bairro",
      NEIGHBORHOOD_WAS_ENTERED_CORRECT: "O bairro foi inserida corretamente",
      NEIGHBORHOOD_IS_REQUIRED: "O bairro é obrigatório",
      NEIGHBORHOOD_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "O bairro deve ter pelo menos 3 símbolos",
      NEIGHBORHOOD_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "O bairro deve ter no máximo 100 símbolos",
      POSTAL_CODE: "Código postal",
      POSTAL_CODE_WAS_ENTERED_CORRECT:
        "O código postal foi inserida corretamente",
      POSTAL_CODE_IS_REQUIRED: "O código postal é obrigatório",
      POSTAL_CODE_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "O código postal deve ter pelo menos 3 símbolos",
      POSTAL_CODE_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "O código postal deve ter no máximo 100 símbolos",
      SELECT_STATE: "Selecione o estado",
      STATE: "Estado",
      SELECT_CITY: "Selecione a cidade",
      CITY: "Cidade",
      CANCEL: "Cancelar",
      SAVE: "Salvar",
    },
    DELETE_USER_ADDRESS: {
      USER_ADDRESS_DELETE: "Excluir endereço do usuário",
      USER_ADDRESS_IS_DELETING: "O endereço do usuário está sendo excluido...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_ADDRESS:
        "Tem certeza de que deseja excluir permanentemente este endereço de usuário?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    DELETE_USERS_ADDRESS: {
      USERS_ADDRESS_DELETE: "Excluir endereço dos usuários",
      USERS_ADDRESS_IS_DELETING:
        "Os endereços dos usuários estão sendo excluidos...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_ADDRESS:
        "Tem certeza de que deseja excluir permanentemente o endereço dos usuários selecionados?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    FETCH_USERS_ADDRESS: {
      FETCH_SELECTED_USERS_ADDRESS:
        "Buscar o endereço dos usuários selecionados",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      FETCH: "Buscar",
    },
    UPDATE_USERS_ADDRESS: {
      STATUS_UPDATE_FOR_SELECTED_USERS_ADDRESS:
        "Atualização de status para endereços de usuários selecionados",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      UPDATE_STATUS: "Atualizar o status",
    },
    USER_ACCESS_MANAGEMENT: {
      USER_ACCESS_MANAGEMENT_LIST:
        "Lista de gerenciamento de acesso do usuário",
      NEW_USER_ACCESS_MANAGEMENT: "Gerenciamento de acesso de novo usuário",
      FILTER_BY_STATUS: "Filtrar por status",
      ALL: "Tudo",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      FILTER: "Filtro",
      BY_STATUS: " por status",
      FILTER_BY_TYPE: "Filtrar por tipo",
      BUSINESS: "Negócio",
      INDIVIDUAL: "Individual",
      BY_TYPE: " por tipo",
      SEARCH: "Pesquisar",
      IN_ALL_FIELDS: "em todos os campos",
      SELECTED_RECORDS_COUNT: "Contagem de registros selecionados:",
      DELETE_ALL: "Apagar tudo",
      FETCH_SELECTED: "Buscar selecionado",
      UPDATE_STATUS: "Atualizar o status",
      ID: "ID",
      USER_EMAIL_USERNAME: "E-mail do usuário/ Nome do usuário",
      USER_REALNAME: "Nome real do usuário",
      ENABLED: "Ativado",
      CREDENTIALS_EXPIRED: "Credenciais expiradas",
      EXPIRED: "Expirado",
      LOCKED: "Bloqueado",
      ACCESS_EXP_DATE: "Data de exp. do acesso",
      DISABLED: "Desativado",
      UNLOCKED: "Desbloqueado",
      ACTIONS: "Ações",
    },
    EDIT_USER_ACCESS_MANAGEMENT: {
      EDIT: "Editar",
      CREATE: "Criar",
      USER_ACCESS_MANAGEMENT: "gestão de acesso do usuário",
      USER_EMAIL_USERNAME: "E-mail do usuário/ Nome do usuário",
      USER_REALNAME: "Nome real do usuário",
      SELECT_ENABLED: "Selecione habilitado",
      ENABLED: "Ativado",
      DISABLED: "Desativado",
      PLEASE_SELECT: "Por favor selecione",
      SELECT_CREDENTIALS_EXPIRED: "Selecione credenciais expiradas",
      CREDENTIALS_EXPIRED: "credenciais expiradas",
      EXPIRED: "Expirado",
      ACTIVE: "Ativo",
      SELECT_EXPIRED: "Selecione expirado",
      SELECT_LOCKED: "Selecione bloqueado",
      UNLOCKED: "Desbloqueado",
      LOCKED: "Bloqueado",
      ACCESS_EXP_DATE: "Data de expiração do acesso",
      DD_MM_YYYY: "DD/MM/AAAA",
      ACCESS_EXPIRATION_DATE_WAS_ENTERED_CORRECT:
        "A data do vencimento do acesso foi inserida corretamente",
      PLEASE_ENTER: "Por favor, insira",
      IN_DD_MM_YYYY_FORMAT: "no formato DD/MM/AAAA",
      CANCEL: "Cancelar",
      SAVE: "Salvar",
    },
    DELETE_USER_ACCESS_MANAGEMENT: {
      USER_ACCESS_MANAGEMENT_DELETE: "Excluir gestão de acesso do usuário",
      USER_ACCESS_MANAGEMENT_IS_DELETING:
        "A gestão de acesso do usuário está excluindo ...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_ACCESS_MANAGEMENT:
        "Tem certeza de que deseja excluir permanentemente está gestão de acesso do usuário?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    DELETE_USERS_ACCESS_MANAGEMENT: {
      USERS_ACCESS_MANAGEMENT_DELETE: "Excluir gestão de acesso do usuários",
      USERS_ACCESS_MANAGEMENT_IS_DELETING:
        "As gestões de acesso dos usuários estão sendo excluidas ...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_ACCESS_MANAGEMENT:
        "Tem certeza de que deseja excluir permanentemente as gestões de acesso dos usuários selecionados?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    FETCH_USERS_ACCESS_MANAGEMENT: {
      FETCH_SELECTED_USERS_ACCESS_MANAGEMENT:
        "Buscar gestão de acesso dos usuários",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      FETCH: "Buscar",
    },
    UPDATE_USERS_ACCESS_MANAGEMENT: {
      STATUS_UPDATE_FOR_SELECTED_USERS_ACCESS_MANAGEMENT:
        "Atualização de status para gestão de acesso do usuário selecionados",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      UPDATE_STATUS: "Atualizar o status",
    },
    LOGINS: {
      LOGINS_LIST: "Lista de logins",
      FILTER_BY_STATUS: "Filtrar por status",
      ALL: "Tudo",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      FILTER: "Filtro",
      BY_STATUS: " por status",
      FILTER_BY_TYPE: "Filtrar por tipo",
      BUSINESS: "Negócio",
      INDIVIDUAL: "Individual",
      BY_TYPE: " por tipo",
      SEARCH: "Pesquisar",
      IN_ALL_FIELDS: " em todos os campos",
      SELECTED_RECORDS_COUNT: "Contagem de registros selecionados:",
      DELETE_ALL: "Apagar tudo",
      FETCH_SELECTED: "Buscar selecionado",
      UPDATE_STATUS: "Atualizar o status",
      ID: "ID",
      NAME: "Nome",
      CREATE_DATE: "Data de criação",
      IP_ADDRESS: "Endereço de IP",
    },
    USERS_EBI: {
      USERS_EBI_LIST: "Lista do power BI dos usuários",
      NEW_USER_EBI: "Novo usuário power BI",
      FILTER_BY_STATUS: "Filtrar por status",
      ALL: "Tudo",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      FILTER: "Filtro",
      BY_STATUS: " por status",
      FILTER_BY_TYPE: "Filtrar por tipo",
      BUSINESS: "Negócio",
      INDIVIDUAL: "Individual",
      BY_TYPE: " por tipo",
      SEARCH: "Pesquisar",
      IN_ALL_FIELDS: " em todos os campos",
      SELECTED_RECORDS_COUNT: "Contagem de registros selecionados:",
      DELETE_ALL: "Apagar tudo",
      FETCH_SELECTED: "Buscar selecionado",
      UPDATE_STATUS: "Atualizar o status",
      ID: "ID",
      USER: "Usuário",
      USER_LEVEL: "Nível do usuário",
      DESCRIPTION: "Descrição",
      EBI_TYPE: "Tipo do power BI",
      ACTIONS: "Ações",
    },
    EDIT_USER_EBI: {
      EDIT: "Editar",
      CREATE: "Criar",
      USER_EBI: "power BI do usuário",
      SELECT_USER: "Selecione o usuário",
      PLEASE_SELECT: "Por favor selecione",
      USER: " usuário",
      SELECT_USER_LEVEL: "Selecione o nível do usuário",
      USER_LEVEL: " nível do usuário",
      SELECT_EBI_TYPE: "Selecione o tipo do power BI",
      EBI_TYPE: " tipo do power BI ",
      DESCRIPTION: "Descrição",
      DESCRIPTION_WAS_ENTERED_CORRECT: "A descrição foi inserida corretamente",
      DESCRIPTION_IS_REQUIRED: "A descrição é obrigatória",
      DESCRIPTION_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "A descrição deve ter pelo menos 3 símbolos",
      DESCRIPTION_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "A descrição deve ter no máximo 100 símbolos",
      EBI_URL: "URL do power BI",
      EBI_URL_WAS_ENTERED_CORRECT:
        "A URL do power BI foi inserida corretamente",
      EBI_URL_IS_REQUIRED: "A URL do power BI é obrigatória",
      EBI_URL_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "A URL do power BI deve ter pelo menos 3 símbolos",
      EBI_URL_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "A URL do power BI deve ter no máximo 100 símbolos",
      CANCEL: "Cancelar",
      SAVE: "Salvar",
    },
    DELETE_USER_EBI: {
      USER_EBI_DELETE: "Excluir usuário do power BI",
      USER_EBI_IS_DELETING: "A usuário do power BI está excluindo ...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_EBI:
        "Tem certeza de que deseja excluir permanentemente este usuário do power BI?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    DELETE_USERS_EBI: {
      USERS_EBI_DELETE: "Excluir usuários do power BI",
      USERS_EBI_IS_DELETING:
        "Os usuários do power BI estão sendo excluidos ...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_EBI:
        "Tem certeza de que deseja excluir permanentemente os usuários do power BI selecionados?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    FETCH_USERS_EBI: {
      FETCH_SELECTED_USERS_EBI: "Buscar os usuários power BI selecionados",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      FETCH: "Buscar",
    },
    UPDATE_USERS_EBI: {
      STATUS_UPDATE_FOR_SELECTED_USERS_EBI:
        "Atualização de status para os usuários power BI selecionados",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      UPDATE_STATUS: "Atualizar o status",
    },
    USERS_MAP: {
      USERS_MAP_LIST: "Lista de mapas de usuários",
      NEW_USER_MAP: "Novo mapa do usuário ",
      FILTER_BY_STATUS: "Filtrar por status",
      ALL: "Tudo",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      FILTER: "Filtro",
      BY_STATUS: " por status",
      FILTER_BY_TYPE: "Filtrar por tipo",
      BUSINESS: "Negócio",
      INDIVIDUAL: "Individual",
      BY_TYPE: " por tipo",
      SEARCH: "Pesquisar",
      IN_ALL_FIELDS: " em todos os campos",
      SELECTED_RECORDS_COUNT: "Contagem de registros selecionados:",
      DELETE_ALL: "Apagar tudo",
      FETCH_SELECTED: "Buscar selecionado",
      UPDATE_STATUS: "Atualizar o status",
      ID: "ID",
      USER: "Usuário",
      USER_LEVEL: "Nível do usuário",
      USER_MAP_2D: "Mapa do usuário 2D",
      USER_MAP_3D: "Mapa do usuário 3D",
      ACTIONS: "Ações",
    },
    EDIT_USER_MAP: {
      EDIT: "Editar",
      CREATE: "Criar",
      USER_MAP: "mapa do usuário",
      SELECT_USER: "Selecione o usuário",
      PLEASE_SELECT: "Por favor selecione",
      USER: " usuário",
      SELECT_USER_LEVEL: "Selecione o nível do usuário",
      USER_LEVEL: " nível do usuário",
      SELECT_USER_MAP_2D: "Selecione o mapa do usuário 2D",
      SELECT_USER_MAP_3D: "Selecione o mapa do usuário 3D",
      USER_MAP_2D: "mapa do usuário 2D",
      USER_MAP_3D: "mapa do usuário 3D",
      CANCEL: "Cancelar",
      SAVE: "Salvar",
    },
    DELETE_USER_MAP: {
      USER_MAP_DELETE: "Excluir mapa do usuário",
      USER_MAP_IS_DELETING: "O mapa do usuário está excluindo ...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_MAP:
        "Tem certeza de que deseja excluir permanentemente este mapa do usuário?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    DELETE_USERS_MAP: {
      USERS_MAP_DELETE: "Excluir mapa dos usuários",
      USERS_MAP_IS_DELETING: "O mapa dos usuários estão sendo excluidos ...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_MAP:
        "Tem certeza de que deseja excluir permanentemente o mapa dos usuários selecionados?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    FETCH_USERS_MAP: {
      FETCH_SELECTED_USERS_MAP: "Buscar mapa dos usuários selecionados",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      FETCH: "Buscar",
    },
    UPDATE_USERS_MAP: {
      STATUS_UPDATE_FOR_SELECTED_USERS_MAP:
        "Atualização de status para o mapa dos usuários selecionados",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      UPDATE_STATUS: "Atualizar o status",
    },
    PLANS: {
      YOU_ALREADY:
        "Você já possui uma assinatura ativa, portanto não é possível realizar uma nova assinatura. Em caso de dúvidas sobre os planos, entre em contato com a equipe do comercial.",
      HOME_BUTTON: "Início",
      PLANS_LIST: "Lista de mapas de usuários",
      NEW_PLAN: "Novo mapa do usuário ",
      FILTER_BY_STATUS: "Filtrar por status",
      ALL: "Tudo",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      FILTER: "Filtro",
      BY_STATUS: " por status",
      FILTER_BY_TYPE: "Filtrar por tipo",
      BUSINESS: "Negócio",
      INDIVIDUAL: "Individual",
      BY_TYPE: " por tipo",
      SEARCH: "Pesquisar",
      IN_ALL_FIELDS: " em todos os campos",
      SELECTED_RECORDS_COUNT: "Contagem de registros selecionados:",
      DELETE_ALL: "Apagar tudo",
      FETCH_SELECTED: "Buscar selecionado",
      UPDATE_STATUS: "Atualizar o status",
      NAME: "Nome",
      PRICE: "Preço",
      ACTIONS: "Ações",
    },
    EDIT_PLAN: {
      EDIT: "Editar",
      CREATE: "Criar",
      PLAN: "Plano",
      Start: "Começar",
      NAME: "Nome",
      NAME_WAS_ENTERED_CORRECT: "O nome foi inserida corretamente",
      NAME_IS_REQUIRED: "O nome é obrigatória",
      NAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "O nome deve ter pelo menos 3 símbolos",
      NAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "O nome deve ter no máximo 100 símbolos",
      PRICE: "Preço",
      PRICE_WAS_ENTERED_CORRECT: "O preço foi inserida corretamente",
      PRICE_IS_REQUIRED: "O preço é obrigatória",
      PRICE_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "O preço deve ter pelo menos 3 símbolos",
      PRICE_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "O preço deve ter no máximo 100 símbolos",
      REFERENCE_CODE: "Código de referência",
      REFERENCE_CODE_WAS_ENTERED_CORRECT:
        "O código de referência foi inserida corretamente",
      REFERENCE_CODE_IS_REQUIRED: "O código de referência é obrigatória",
      REFERENCE_CODE_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "O código de referência deve ter pelo menos 3 símbolos",
      REFERENCE_CODE_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "O código de referência deve ter no máximo 100 símbolos",
      ACCESS_DURATION: "Duração de acesso",
      ACCESS_DURATION_WAS_ENTERED_CORRECT:
        "A duração de acesso foi inserida corretamente",
      ACCESS_DURATION_IS_REQUIRED: "A duração de acesso é obrigatória",
      ACCESS_DURATION_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "A duração de acesso deve ter pelo menos 3 símbolos",
      ACCESS_DURATION_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "A duração de acesso deve ter no máximo 100 símbolos",
      CANCEL: "Cancelar",
      SAVE: "Salvar",
    },
    DELETE_PLAN: {
      PLAN_DELETE: "Excluir plano",
      PLAN_IS_DELETING: "O plano está excluindo ...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_PLAN:
        "Tem certeza de que deseja excluir permanentemente este plano?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    DELETE_PLANS: {
      PLANS_DELETE: "Excluir planos",
      PLANS_IS_DELETING: "Os planos estão sendo excluidos ...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_PLANS:
        "Tem certeza de que deseja excluir permanentemente os planos selecionados?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    FETCH_PLANS: {
      FETCH_SELECTED_PLANS: "Buscar os planos selecionados",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      FETCH: "Buscar",
    },
    UPDATE_PLANS: {
      STATUS_UPDATE_FOR_SELECTED_PLANS:
        "Atualização de status para os planos selecionados",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      UPDATE_STATUS: "Atualizar o status",
    },
    SITES: {
      SITES_LIST: "Lista de sites",
      NEW_SITE: "Novo site",
      FILTER_BY_STATUS: "Filtrar por status",
      ALL: "Tudo",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      FILTER: "Filtro",
      BY_STATUS: " por status",
      FILTER_BY_TYPE: "Filtrar por tipo",
      BUSINESS: "Negócio",
      INDIVIDUAL: "Individual",
      BY_TYPE: " por tipo",
      SEARCH: "Pesquisar",
      IN_ALL_FIELDS: " em todos os campos",
      SELECTED_RECORDS_COUNT: "Contagem de registros selecionados:",
      DELETE_ALL: "Apagar tudo",
      FETCH_SELECTED: "Buscar selecionado",
      UPDATE_STATUS: "Atualizar o status",
      NAME: "Nome",
      PRIMARY_COLOR: "Cor primária",
      SECONDARY_COLOR: "Cor secundária",
      ACCENT_COLOR: "Cor de destaque",
      ACTIONS: "Ações",
    },
    DELETE_SITE: {
      SITE_DELETE: "Excluir site",
      SITE_IS_DELETING: "O site está excluindo ...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_SITE:
        "Tem certeza de que deseja excluir permanentemente este site?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    DELETE_SITES: {
      SITES_DELETE: "Excluir sites",
      SITES_IS_DELETING: "Os sites estão sendo excluidos ...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_SITES:
        "Tem certeza de que deseja excluir permanentemente os sites selecionados?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    FETCH_SITES: {
      FETCH_SELECTED_SITES: "Buscar os sites selecionados",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      FETCH: "Buscar",
    },
    UPDATE_SITES: {
      STATUS_UPDATE_FOR_SELECTED_SITES:
        "Atualização de status para os sites selecionados",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      UPDATE_STATUS: "Atualizar o status",
    },
    LEVELS: {
      LEVELS_LIST: "Lista de níveis",
      NEW_LEVEL: "Novo nível",
      FILTER_BY_STATUS: "Filtrar por status",
      ALL: "Tudo",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      FILTER: "Filtro",
      BY_STATUS: " por status",
      FILTER_BY_TYPE: "Filtrar por tipo",
      BUSINESS: "Negócio",
      INDIVIDUAL: "Individual",
      BY_TYPE: " por tipo",
      SEARCH: "Pesquisar",
      IN_ALL_FIELDS: " em todos os campos",
      SELECTED_RECORDS_COUNT: "Contagem de registros selecionados:",
      DELETE_ALL: "Apagar tudo",
      FETCH_SELECTED: "Buscar selecionado",
      UPDATE_STATUS: "Atualizar o status",
      NAME: "Nome",
      ACTIONS: "Ações",
    },
    EDIT_LEVEL: {
      EDIT: "Editar",
      CREATE: "Criar",
      LEVEL: "nível",
      NAME: "Nome",
      NAME_WAS_ENTERED_CORRECT: "O nome foi inserida corretamente",
      NAME_IS_REQUIRED: "O nome é obrigatória",
      NAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "O nome deve ter pelo menos 3 símbolos",
      NAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "O nome deve ter no máximo 100 símbolos",
      SELECT_VIEW_MENU: "Selecione o menu de visualização",
      VIEW_MENU: " ver menu",
      SELECT_VIEW_ASSETS: "Selecione visualizar ativos",
      VIEW_ASSETS: " ver ativos",
      SELECT_VIEW_NOTIFICATIONS: "Selecione ver notificações",
      VIEW_NOTIFICATIONS: " ver notificações",
      SELECT_VIEW_CHATS: "Selecione  ver chats",
      VIEW_CHATS: " ver chats",
      SELECT_VIEW_OPPORTUNITIES: "Selecione ver oportunidades",
      VIEW_OPPORTUNITIES: " ver oportunidades",
      SELECT_VIEW_ACTIVE_INFOMAPS: "Selecione ver mapas de informação ativos",
      VIEW_ACTIVE_INFOMAPS: " ver mapas de informação ativos",
      SELECT_VIEW_MAPBOX: "Selecione  ver caixa de mapa",
      VIEW_MAPBOX: " ver caixa de mapa",
      SELECT_VIEW_EBI: "Selecione ver power BI ",
      VIEW_EBI: " ver power BI",
      SELECT_VIEW_ECOMAP: "Selecione ver mapa ecológico",
      VIEW_ECOMAP: " ver mapa ecológico",
      SELECT_VIEW_ECOBOARD: "Selecione ver quadro ecológico",
      VIEW_ECOBOARD: " ver quadro ecológico",
      SELECT_VIEW_ECOFLOW: "Selecione a visualização do fluxo ecológico",
      VIEW_ECOFLOW: " ver fluxo ecológico",
      ENABLED: "Ativado",
      DISABLED: "Desativado",
      PLEASE_SELECT: "Por favor selecione",
      CANCEL: "Cancelar",
      SAVE: "Salvar",
    },
    DELETE_LEVEL: {
      LEVEL_DELETE: "Excluir nível",
      LEVEL_IS_DELETING: "O nível está excluindo ...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_LEVEL:
        "Tem certeza de que deseja excluir permanentemente este nível?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    DELETE_LEVELS: {
      LEVELS_DELETE: "Excluir níveis",
      LEVELS_IS_DELETING: "Os níveis estão sendo excluidos ...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_LEVELS:
        "Tem certeza de que deseja excluir permanentemente os níveis selecionados?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    FETCH_LEVELS: {
      FETCH_SELECTED_LEVELS: "Buscar os níveis selecionados",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      FETCH: "Buscar",
    },
    UPDATE_LEVELS: {
      STATUS_UPDATE_FOR_SELECTED_LEVELS:
        "Atualização de status para os níveis selecionados",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      UPDATE_STATUS: "Atualizar o status",
    },
    ROLES: {
      ROLES_LIST: "Lista de papeis",
      NEW_ROLE: "Novo papel",
      FILTER_BY_STATUS: "Filtrar por status",
      ALL: "Tudo",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      FILTER: "Filtro",
      BY_STATUS: " por status",
      FILTER_BY_TYPE: "Filtrar por tipo",
      BUSINESS: "Negócio",
      INDIVIDUAL: "Individual",
      BY_TYPE: " por tipo",
      SEARCH: "Pesquisar",
      IN_ALL_FIELDS: " em todos os campos",
      SELECTED_RECORDS_COUNT: "Contagem de registros selecionados:",
      DELETE_ALL: "Apagar tudo",
      FETCH_SELECTED: "Buscar selecionado",
      UPDATE_STATUS: "Atualizar o status",
      NAME: "Nome",
      DESCRIPTION: "Descrição",
      ACTIONS: "Ações",
    },
    DELETE_ROLE: {
      ROLE_DELETE: "Excluir papel",
      ROLE_IS_DELETING: "O papel está excluindo ...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_ROLE:
        "Tem certeza de que deseja excluir permanentemente este papel?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    DELETE_ROLES: {
      ROLES_DELETE: "Excluir papeis",
      ROLES_IS_DELETING: "Os papeis estão sendo excluidos ...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_ROLES:
        "Tem certeza de que deseja excluir permanentemente os papeis selecionados?",
      CANCEL: "Cancelar",
      DELETE: "Deletar",
    },
    EDIT_ROLE: {
      EDIT: "Editar",
      CREATE: "Criar",
      ROLE: "papel",
      NAME: "Nome",
      NAME_WAS_ENTERED_CORRECT: "O nome foi inserida corretamente",
      NAME_IS_REQUIRED: "O nome é obrigatória",
      NAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "O nome deve ter pelo menos 3 símbolos",
      NAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "O nome deve ter no máximo 100 símbolos",
      DESCRIPTION: "Descrição",
      DESCRIPTION_WAS_ENTERED_CORRECT: "A descrição foi inserida corretamente",
      DESCRIPTION_IS_REQUIRED: "A descrição é obrigatória",
      DESCRIPTION_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "A descrição deve ter pelo menos 3 símbolos",
      DESCRIPTION_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "A descrição deve ter no máximo 100 símbolos",
      CANCEL: "Cancelar",
      SAVE: "Salvar",
    },
    FETCH_ROLES: {
      FETCH_SELECTED_ROLES: "Buscar os papeis selecionados",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      FETCH: "Buscar",
    },
    UPDATE_ROLES: {
      STATUS_UPDATE_FOR_SELECTED_ROLES:
        "Atualização de status para os papeis selecionados",
      FIRSTNAME: "Primeiro nome",
      LASTNAME: "Último nome",
      STATUS: "Status",
      SUSPENDED: "Suspenso",
      ACTIVE: "Ativo",
      PENDING: "Pendente",
      CANCEL: "Cancelar",
      UPDATE_STATUS: "Atualizar o status",
    },
    MAP_LIST: {
      MAPS_LIST: "Lista de Mapas",
      MAPS: "Meus Mapas",
      WORKSPACES: "Meus Workspaces",
      NEW_MAP: "Novo Workspace",
      EDIT_MAP: "Editar Mapa",
      DELETE_MAP: "Excluir Mapa",
      MAP_NAME: "Nome do Mapa",
      CREATE_NEW_MAP: "Criar um novo mapa vazio",
      COPY_MAP: "Criar um novo mapa a partir de um mapa existente",
      SELECT_MAP: "Selecione um mapa para copiar",
      SAVE: "Salvar",
      NAME: "Nome",
    },
    EDIT_MAP: {
      PITCH: "Inclinação",
      BEARING: "Orientação",
      EDIT: "Editar Mapa",
      BASIC_INFO: "Informações Básicas",
      LAYERS: "Layers",
      FILTERS: "Filtros",
      ORIENTED_SEARCHES: "Buscas Orientadas",
      NAME: "Nome",
      INITIAL_ZOOM: "Zoom Inicial",
      SELECT_BASEMAP: "Thumbnail",
      PLEASE_SELECT: "Por favor selecione um ",
      BASEMAP: "Mapa Base",
      CENTER_LATITUDE: "Latitude do Centro",
      CENTER_LONGITUDE: "Longitude do Centro",
      ROLE: "Cargo",
      CANCEL: "Cancelar",
      SAVE: "Salvar",
      BACK: "Voltar",
      ADD_LAYER: "Adicionar Layer",
      CREATE: "Criar Mapa",
    },
    DELETE_MAP: {
      MAP_DELETE: "Deletar mapa",
      MAP_IS_DELETING: "O mapa está sendo deletado...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_MAP:
        "Você tem certeza que deseja deletar esse mapa?",
    },
    LAYERS: {
      LAYERS_LIST: "Lista de layers",
      SEARCH: "Pesquisar",
      IN_ALL_FIELDS: " em todos os campos",
      NAME: "Nome",
      DISPLAY_NAME: "Nome no Display",
      CREATED: "Data de Criação",
      VERSION: "Versão",
      ACTIONS: "Ações",
    },
    CREATE_MAP_LAYER: {
      PUBLISHED_LAYER: "Layer Publicada",
      CANCEL: "Cancelar",
    },
    PLAN: {
      OPENING_INFORMATION: "Informações de abertura",
      OPENING_INFORMATION_DESCRIPTION:
        "(Leilões, Notas Técnicas, Informes, etc)",
      ELETRIC_ENERGY_GENERATORS: "Geradores de energia elétrica",
      ELETRIC_ENERGY_GENERATORS_DESCRIPTION:
        "(Usinas de geração de energia informadas pela ANEEL, ONS, EPE, ANP, etc) ",
      ELETRIC_ENERGY_TRANSPORTATION: "Transporte de energia elétrica",
      ELETRIC_ENERGY_TRANSPORTATION_DESCRIPTION:
        "(Subestações de distribuição/rede básica, Rede de Média Tensão da distribuidora, Rede Básica completa e seus estudos de expansão)",
      MODAL_TRANSPORTATION_INFRASTRUCTURE:
        "Infra estrutura de modais de transporte",
      MODAL_TRANSPORTATION_INFRASTRUCTURE_DESCRIPTION:
        "(Aeroportos, ferrovias, rodovias existentes e planejadas, etc)",
      AMBIENTAL_INFORMATION: "Informações ambientais",
      AMBIENTAL_INFORMATION_DESCRIPTION:
        "(MMA, INCRA, CAR, Agência Nacional de Águas, terras indígenas e quilombolas, cavernas, áreas de conservação de aves, geomorfologia/geodiversidade, mineração, etc)",
      ENERGETIC_POTENTIAL: "Potencial energético",
      ENERGETIC_POTENTIAL_DESCRIPTION:
        "(Atlas Solarimétrico e Atlas do potencial eólico brasileiro)",
      CLOSING_BLOCK: "Bloco de fechamento",
      CLOSING_BLOCK_DESCRIPTION:
        "(IBGE, Mapa planialtimétrico, imagens de satélites atualizadas, outros)",
      START_BUTTON: "Continuar",
      TALK_TO_US: "Fale Conosco",
      V1: "550,00/mês",
      V2: "1215,00/mês",
    },
    WATCHED_VIDEO: {
      WATCH_VIDEO: "Assistir vídeo",
      NEXT: "Próximo",
    },
    READ_TERMS: {
      READ_TERMS: "Aceitar termos",
      I_AGREE_TO_THE_TERMS: "Concordo com os termos",
      CONTINUE: "Continuar",
    },
    EBI: {
      DASHBOARDS_PANEL: "Painel de Dashboards",
    },
    LEADS: {
      YES:"SIM",
      NO:"NÃO",
      DISTRIBUTOR: "Distribuidora",
      CEG: "Possui GD",
      CONSUMPTION_CLASS: "Classe de Consumo",
      DEMAND: "Demanda estimada",
      FREE_MARKET: "Mercado Livre",
      CITY: "Cidade",
      DISTRICT: "Bairro",
      TARIFF_GROUP: "Grupo Tarifário",
      MINIMUM: "Mínimo",
      MAXIMUM: "Máximo",
      SELECTED_LEAD_VALUE: "Valor Total:",
      SELECTED_LEAD_AMOUNT: "LEADS Selecionados:",
      ACCOUNT_VALUE: "Valor da conta",
      CONSUMPTION: "Consumo mensal médio",
      LEADS_EXPLANATION_DATE:
        "Cada lead comprado entra em um processo de retenção aonde ficará indisponível para outras empresas durante um prazo de 3 meses.",
      NEXT: "Próximo",
      CANCEL: "Cancelar",
      CONFIRM: "Confirmar",
      LEADS_QUANTITY: "Quantidades de LEADS selecionados",
      LEADS_AVAILABLE: "Leads disponiveis até",
      CONFIRM_PURCHASE: "Gostaria de confirmar sua compra",
      FEDERATIVE_UNIT: "Unidade Federativa",
      LEADS_SELECT_QUANTITY: "Selecione a quantidade de LEADS",
      CONFIRM_REQUEST: "Pedido Confirmado",
      LEADS_THANKS_MESSAGE: "A Datlaz agradece a confiança.",
      CLOSE: "FECHAR",
      ORDER_NUMBER: "Número do Pedido",
      MESSAGE_ERROR_FILL_BOTH: "Favor inserir um valor em ambos os campos.",
      MESSAGE_ERROR_MIN_VALUE_DIFF:
        "O valor mínimo deve ser menor que o valor máximo.",
      AVAILABLE_LEDS_BY_REGION: "LEADS disponíveis nesta região.",
      MAXIMUM_LEADS: "Máximo de 1.000 LEADS por pedido.",
      SEARCH: "Buscar",
    },
    TUTORIALS: {
      LIST_TUTORIALS: "Lista de tutoriais",
    },
    GED: {
      SHORT_NAME: "Documentos",
      EXTENDED_NAME: "Gerenciamento Eletrônico de Documentos",
      NAME: "Nome",
      SIZE: "Tamanho",
      CREATED: "Data de criação",
      UPDATED: "Data de atualização",
      RENAME: "Renomear",
      COPY_LINK: "Copiar link",
      DOWNLOAD: "Baixar",
      DELETE: "Deletar",
      ITEMS: "Itens",
      UPLOAD_FILE: "Upload de arquivo",
      NEW_FOLDER: "Nova pasta",
      ACTIONS: "Ações",
      FOLDER: "Pasta",
      FILE: "Arquivo",
      CANCEL: "Cancelar",
      SAVE: "Salvar",
      IS_PUBLIC: "Arquivo público?",
      EXTENSION:"Extensão",
      GED_DELETE: {
        FOLDER_DELETE: "Excluir pasta",
        FOLDER_IS_DELETING: "A pasta está sendo excluída...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_FOLDER:
          "Tem certeza de que deseja excluir permanentemente esta pasta?",
        FILE_DELETE: "Excluir arquivo",
        FILE_IS_DELETING: "O arquivo está sendo excluído...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_FILE:
          "Tem certeza de que deseja excluir permanentemente este arquivo?",
      },
      GED_FILE:{
        UPLOAD_FILE: "Carregar arquivo"
      },
      GED_FOLDER:{
        CREATE_FOLDER: "Criar pasta"
      }
      ,
      EMPTY_FOLDER_MESSAGE:{
        NO_CONTENT: "Esta pasta não contém nenhum conteúdo.",
        CREATE_FOLDER: "Crie uma pasta",
        OR:" ou",
        FILE_UPLOAD: "faça upload de um arquivo",
        START: "para começar.",
      }
    }
  },
};
