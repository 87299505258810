// USA
export const locale = {
  lang: "en",
  data: {
    USER_FEEDBACK_DROPDOWN: {
      SHARE_YOUR_FEEDBACK: "Share your Feedback",
      RATING: "Rating",
      MESSAGE: "Message",
      SEND: "Send",
      INVALID_FORM_MESSAGE:
        "Please select a rating and write a message before sending feedback.",
      THANK_YOU_FOR_YOUR_FEEDBACK_TITLE:
        "Thank you very much for your feedback!",
      THANK_YOU_FOR_YOUR_FEEDBACK_TEXT:
        "We sincerely appreciate you sharing your opinion with us. Your feedback is extremely valuable and helps us continually improve our platform.",
    },
    USER_DROPDOWN: {
      NEW_GROUP: "New Group",
      CONTACTS: "Contacts",
      GROUPS: "Groups",
      CALLS: "Calls",
      SETTINGS: "Settings",
      HELP: "Help",
      PRIVACY: "Privacy",
    },
    ERROR: {
      OOPS: "OOPS! Something went wrong here",
    },
    USER_NOTIFICATION: {
      IMPORT_IN_PROGRESS: "Import in progress",
      IMPORT_COMPLETED: "Import completed",
      FAILED_IMPORT: "Failed import",
      NEW_NOTIFICATIONS: "New",
      ALERTS: "Alerts",
      EVENTS: "Events",
      ALL_CAUGHT_UP: "All caught up!",
      NO_NEW_NOTIFICATIONS: "No new notifications.",
    },
    KEYWORDS: {
      PREVIOUS: "Previous",
      NEXT: "Next",
      CLOSE: "Close",
      CONFIRM: "Confirm",
    },
    TRANSLATOR: {
      SELECT: "Select your language",
    },
    PAGINATOR: {
      LOADING: "Loading...",
      SHOWING: "Showing rows",
      TO: "to",
      OF: "of",
    },
    ORDERS: {
      SELECTED_AREA: "Selected area",
      RETENTION_END_PERIOD: "Final retention period",
      TOTAL_LEADS: "Total leads",
      LEADS_DETAILS: "Leads details",
      IN_PROGRESS: "In progress",
      ERROR: "Error",
      SUCCESS: "Success",
      PENDING: "Pending",
      CANCELED: "Canceled",
      TRIM_EXPORTER: "Trim",
      LEADS: "Leads",
      ORDER_CREATED: "Creation date",
      TRIM_DETAILS: "Trim details",
      DISPLAY_NAME: "Name",
      IS_OUTDATED: " Is outdated",
      MEDIA_FILE_URL: "Link to the file",
      UPDATE_DATE: "Update date",
      LAYER_NAME: "Layer name",
      PRICE: "Price",
      FALSE: "No",
      TRUE: "Yes",
    },
    PAYMENT: {
      COUPON_HAS_ALREADY_BEEN_USED: "This coupon has already been used",
      INSERT_COUPON: "Enter coupon",
      START_TRIAL_PERIOD: "Start trial period",
      PHONE_NUMBER: "Phone number",
      OK: "OK",
      CPF: "CPF",
      CPF_CNPJ: "CPF/CNPJ",
      INVALID_CPF: "Invalid CPF",
      SIGNED_PLAN: "Signed plan",
      CONTINUE: "Confirm",
      NOT_DEFINED: "Not Defined",
      TRIAL: "Trial period",
      FAILED: "Failed",
      PROCESSED: "Processed",
      REQUESTED: "Requested",
      DATE_FORMAT: "MM/YYYY",
      GENERATE_BANKSLIP: "Generate bank slip",
      SELECT_AN_ADDRESS: "Please select an address",
      DISCOUNT_COUPON: "Discount coupon",
      DISCOUNT_APPLY: "Apply discount",
      COUPON_INVALID: "Invalid coupon",
      COUPON_APPLIED: "Coupon applied",
      CARD_MONTH: "Month",
      CARD_YEAR: "Year",
      INFORMATION_ABOUT_CVV:
        "Corresponds to the three numbers that appear on the back of the card",
      CONFIRM: "Confirm payment",
      CARD_CVV: "CVV",
      CARD_EXPIRATION_DATE: "Card expiration date",
      CARD_NUMBER: "Card number",
      CARD_OWNER_NAME: "Card owner name",
      BANK_SLIP: " Bank slip",
      DEBIT_CARD: "Debit card",
      CREDIT_CARD: "Credit card",
      YOUR_PLAN: "Your plan:",
    },
    MENU: {
      CLEAR_ALL: "Clear all",
      YOU_HAVE: "You have",
      DAYS_OF_FREE_TRIAL: "days of free trial.",
      BACKOFFICE: "Backoffice",
      DASHBOARD: "Dashboard",
      EBI: "e-BI",
      LEADS: "Leads",
      ECOMAP: "EcoMap",
      ECNPJ: "e-CNPJ",
      EMAP: "e-Map",
      FEED: "Feed",
      TUTORIALS: "Tutorials",
      WORKSPACE: "Workspace",
      GED: "Documents",
      QUICK_SEARCH: "Quick search",
      NOTIFICATION: "Notification",
      WELCOME_MESSAGE: "Hi,",
      USER: {
        MESSAGE: "Message",
        MY_PROFILE: "My profile",
        DESCRIPTION_PROFILE: "Description profile",
        UPGRADE_PROFILE: "Upgrade profile",
        MY_MESSAGES: "My messages",
        DESCRIPTION_MESSAGES: "Description messages",
        MY_ACTIVITIES: "My activities",
        DESCRIPTION_ACTIVITIES: "Description activities",
        DESCRIPTION_TASKS: "Description tasks",
        SIGN_OUT: "Sign out",
        UPGRADE_PLAN: "Upgrade plan",
      },
      USER_PROFILE: {
        BY_MONTH: "by month",
        UNIQUE_PAYMENT_OF: "Unique payment of",
        NEW_USER_ADDRESS: {
          EDIT: "Edit",
          CREATE: "Create",
          USER_ADDRESS: "User address",
          STREET: "Street",
          STREET_WAS_ENTERED_CORRECT: "Street was entered correct",
          STREET_IS_REQUIRED: "Street is required",
          STREET_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Street should have at least 3 symbols",
          STREET_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Street should have maximum 100 symbols",
          NUMBER: "Number",
          NUMBER_WAS_ENTERED_CORRECT: "Number was entered correct",
          NUMBER_IS_REQUIRED: "Number is required",
          NUMBER_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Number should have at least 3 symbols",
          NUMBER_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Number should have maximum 100 symbols",
          COMPLEMENT: "Complement",
          COMPLEMENT_WAS_ENTERED_CORRECT: "Complement was entered correct",
          COMPLEMENT_IS_REQUIRED: "Complement is required",
          COMPLEMENT_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Complement should have at least 3 symbols",
          COMPLEMENT_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Complement should have maximum 100 symbols",
          NEIGHBORHOOD: "Neighborhood",
          NEIGHBORHOOD_WAS_ENTERED_CORRECT: "Neighborhood was entered correct",
          NEIGHBORHOOD_IS_REQUIRED: "Neighborhood is required",
          NEIGHBORHOOD_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Neighborhood should have at least 3 symbols",
          NEIGHBORHOOD_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Neighborhood should have maximum 100 symbols",
          POSTAL_CODE: "Postal code",
          POSTAL_CODE_WAS_ENTERED_CORRECT: "Postal code was entered correct",
          POSTAL_CODE_IS_REQUIRED: "Postal code is required",
          POSTAL_CODE_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
            "Postal code should have at least 3 symbols",
          POSTAL_CODE_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
            "Postal code should have maximum 100 symbols",
          SELECT_STATE: "Select state",
          SELECT_STATE_TIP: "Please select a state ",
          STATE: "State",
          SELECT_CITY: "Select city",
          SELECT_CITY_TIP: "Please select a city",
          CITY: "City",
          CANCEL: "Cancel",
          SAVE: "Save",
        },

        NUMBER: "Number",
        DATE: " Date",
        MY_REQUESTS: "My requests",
        CARD_NUMBER: "Card Number",
        CARD_EXPIRATION_DATE: "Expiration date",
        CARD_NAME: "Card Name",
        USER_ADDRESS: "User address",
        CHAT: "Chat",
        FOLLOW: "Follow",
        EMAIL: "Email",
        CREDIT_CARDS_LIST: "Credit card list",
        NEW_CREDIT_CARD: "New credit card",
        PHONE: "Phone",
        PROFILE_OVERVIEW: "Profile overview",
        PERSONAL_INFORMATION: "Personal information",
        CHANGE_PASSWORDS: "Change password",
        EMAIL_SETTINGS: "Email settings",
        CREDIT_CARD: "Credit card",
        ADDRESS: "User addresses",
        LOGIN_ACTIVITY: "Login activity",
        DESCRIPTION_ACTIVITY: "Description activity",
        SAVED_CREDIT_CARD: "Saved credit cards",
        PERSONAL_INFO: "Personal Info",
        UPDATE_PERSONAL_INFO: "Update personal info",
        SAVE_CHANGES: "Save changes",
        CANCEL: "Cancel",
        CUSTOMER_INFO: "Customer info",
        AVATAR: "Avatar",
        AVATAR_TIP: "Allowed file types: png, jpg, jpeg.",
        REAL_NAME: "Name",
        LAST_NAME: "First name",
        CONTACT_INFO: "Contact info",
        CONTACT_PHONE: "Contact phone",
        CONTACT_PHONE_TIP: "We will never share your phone with anyone else",
        EMAIL_ADDRESS: "Email address",

        CHANGE_PASSWORD: "Change password",
        CHANGE_YOUR_ACCOUNT_PASSWORD: "Change your account password",
        CURRENT_PASSWORD: "Current password",
        CURRENT_PASSWORD_WAS_ENTERED_CORRECT:
          "Current password was entered correct",
        CURRENT_PASSWORD_IS_REQUIRED: "Current password is required",
        NEW_PASSWORD: "New password",
        VERIFY_PASSWORD: "Verify password",
        PASSWORD_CONFIRMATION_WAS_ENTERED_CORRECT:
          "Password confirmation was entered correct",
        PASSWORD_AND_CONFIRM_PASSWORD_DIDNT_MATCH:
          "'Password' and 'Confirm password' didn't match",

        EMAIL_SETTINGS_2: "Email settings",
        EMAIL_SETTINGS_TIP: "Update your email settings",
        SETUP_EMAIL_NOTIFICATION: "Configure your email Notifications",
        EMAIL_NOTIFICATION: "Email notification",
        SEND_COPY_TO_PERSONAL_EMAIL: "Send copy to personal email",
        ACTIVITY_RELATED_EMAILS: "Activity related emails",
        WHEN_TO_EMAIL: "When to email",
        YOU_HAVE_NEW_NOTIFICATIONS: "You have new notifications",
        YOU_ARE_SENT_A_DIRECT_MESSAGE: "You are sent a direct message",
        SOMEONE_ADDS_YOU_AS_A_CONNECTION: "Someone adds you as a connection",
        WHEN_TO_ESCALATE_EMAIL: "When to escalate email",
        UPON_NEW_ORDER: "Upon new order",
        NEW_MEMBERSHIP_APPROVAL: "New membership approval",
        MEMBER_REGISTRATION: "Member registration",

        UPDATES_FROM_KEENTHEMES: "Updates From Keenthemes",
        EMAIL_YOU_WITH: "Email you with",
        NEWS_ABOUT_KEENTHEMES_PRODUCTS_AND_FEATURE_UPDATES:
          "News about Keenthemes products and feature updates",
        TIPS_ON_GETTING_MORE_OUT_OF_KEEN: "Tips on getting more out of Keen",
        THINGS_YOU_MISSED_SINCE_YOU_LAST_LOGGED_INTO_KEEN:
          "Things you missed since you last logged into Keen",
        NEWS_ABOUT_METRONIC_ON_PARTNER_PRODUCTS_AND_OTHER_SERVICES:
          "Tips on Metronic business products",
        TIPS_ON_METRONIC_BUSINESS_PRODUCTS:
          "Tips on Metronic business products",

        NEW_ADDRESS: "New address",
        ADDRESS_1: "Address 1",
        PRIMARY: "Primary",
        DELETE: "Delete",
        EDIT: "Edit",

        PAYMENTS_LIST: "Payments list",
        PAYMENTS: "Payments",
        VIEW_PAYMENTS: "View payments",
        SUBSCRIPTION_NAME: "Subscription name",
        STATUS: "Status",
        PRICE: "Price",
        TYPE: "Type",
        CREATED: "Created",
        COUPON_CODE_NAME: "Coupon code name",
        PAID_INSTALMENTS: "Paid instalments",
        TOTAL_INSTALMENTS: "Total instalments",
        BOLETO_URL: "Boleto URL",
        BOLETO_BAR_CODE: "Boleto bar code",
        CREDIT_CARD_HASH: "Credit card hash",
        ACTION: "Action",
        CLOSE: "Close",

        
        OWNER: "Owner", 
        MEMBER: "Member",
        MEMBERS: "Members",
        NAME: "Name",
        CNPJ:"CNPJ",
        CORPORATE_NAME:"Corporate name",
        TRADING_NAME:"Trading name",
        TELEPHONE:"Telephine",
        CELLPHONE:"Cellphone",
        CONTACT_NAME:"Contact name",
        CONTACT_EMAIL:"Contact email",
        FINANCIAL_EMAIL:"Financial email",
        FINANCIAL_OFFICER:"Financial officer",
        ADDITIONAL_INFORMATION:"Additional information",
        ORGANIZATION_DETAILS: "Organization details",
        ORGANIZATION_OWNER: "Organization owner",
        INVITE: "Invite",
        SEND: "Send",
        INVITED_EMAIL: "Invited email",
        ROLE: "Role",
        USER_ORG_DELETE: "Delete user from organization",
        USER_ORG_IS_DELETING: "Organization user is deleting...",
        ARE_YOU_SURE_WANT_TO_DELETE_THIS_USER_ORG:
          "Are you sure you want to delete this user org?",
        DO_YOU_ACCEPT_TO_BE_PART_OF_THIS_ORGANIZATION: "Do you accept to be part of this organization?",
        YOU_HAVE_AN_INVITATION: "You have an invitation!",
        YOU_HAVE_AN_INVITATION_MESSAGE: "You have a pending organization invite to be accepted. Please check by ",
        YOU_HAVE_AN_INVITATION_MESSAGE_CLICK: "clicking here",
      },
    },
    SETTINGS: {
      DESCRIPTION:
        "Layout Builder serves to help define and configure as preferred design layout specifications and visualize it in real time. As layout configuration options will be saved until you change or reset as. To use the layout builder, choose as layout options and click the 'Preview' button to preview the changes.",
      BUTTON_PREVIEW: "Preview",
      BUTTON_SAVE: "Save",
      BUTTON_RESET: "Reset",
      VALUES: {
        FIXED: "Fixed",
        FLUID: "Fluid",
        DEFAULT: "Default",
        TAB: "Tab",
        TRANSPARENT: "Transparent",
        SOLID: "Solid",
        SUBHEADER_1: "Subheader 1",
        SUBHEADER_POWERBI: "Subheader Power-BI",
        SUBHEADER_3: "Subheader 3",
        SUBHEADER_4: "Subheader 4",
        SUBHEADER_LEADS: "Subheader Leads",
        SUBHEADER_6: "Subheader 6",
      },
      SITE: {
        SITE: "Site",
        ENTER_NAME: "Name",
        ENTER_NAME_PLACEHOLDER: "Name",
        LOGO: "Logo",
        LOGO_TIP: "These are historical files of the type: png, jpg and jpeg.",
      },
      HEADER: {
        HEADER: "Header",
        DESKTOP_FIXED_HEADER: "Desktop fixed header",
        DESKTOP_FIXED_HEADER_TIP: "Allows fixed header for the desktop",
        MOBILE_FIXED_HEADER: "Mobile fixed header",
        MOBILE_FIXED_HEADER_TIP: "Allows fixed header for mobile",
        WIDTH: "Header width",
        WIDTH_TIP: "Select header width type",
        DISPLAY_HEADER_MENU: "Show header menu",
        DISPLAY_HEADER_MENU_TIP: "Display header menu",
        STATIC_HEADER_MENU: "Display static header menu",
        STATIC_HEADER_MENU_TIP: "'Display static header menu'",
        MENU_LAYOUT: "Layout of the header menu",
        MENU_LAYOUT_TIP: "Select layout type from header menu",
        MENU_ARROWS: "Header menu arrows",
        MENU_ARROWS_TIP: "Allows arrows for header menu",
      },
      SUBHEADER: {
        SUBHEADER: "Subheader",
        DISPLAY_SUBHEADER: "Display subheader",
        DISPLAY_SUBHEADER_TIP: "Display subheader",
        FIXED_SUBHEADER: "Fixed subheader",
        FIXED_SUBHEADER_TIP:
          "Show Fixed Subheader. Requires 'Solid' Subheading style.",
        WIDTH: "Width",
        WIDTH_TIP: "Select the type of layout width",
        STYLE: "Subheader style",
        STYLE_TIP: "Select subheader style",
        LAYOUT: "Subheader layout",
        LAYOUT_TIP: "Select subheader layout",
      },
      CONTENT: {
        CONTENT: "Content",
        WIDTH: "Width",
        WIDTH_TIP: "Select layout width type",
      },
      ASIDE: {
        ASIDE: "Aside",
        DISPLAY: "Display",
        DISPLAY_TIP: "Show aside menu",
        STATIC_ASIDE_MENU: "Static aside menu",
        STATIC_ASIDE_MENU_TIP: "Show static aside menu",
        FIXED: "Fixed",
        FIXED_TIP: "Defined the fixed aside menu layout",
        MINIMIZE: "Minimize",
        MINIMIZE_TIP: "Allows the aside menu to minimize",
        DEFAULT_MINIMIZE: "Minimize pattern",
        DEFAULT_MINIMIZE_TIP: "Allows the aside menu minimize by default",
        SCROLL: "Scroll",
        SCROLL_TIP: "Allows scrolling of the aside menu",
        SUBMENU_TOGGLE_DROPDOWN: "Toggle drop-down list",
        SUBMENU_TOGGLE_DROPDOWN_TIP:
          "Allows toggle drop-down list (Only works when 'SCROLL' is disabled. *By default - mode is 'accordion').",
      },
      FOOTER: {
        FOOTER: "Footer",
        FIXED_DESKTOP_FOOTER: "Fixed desktop footer",
        FIXED_DESKTOP_FOOTER_TIP: "Define fixed footer on the desktop.",
        WIDTH: "Width",
        WIDTH_TIP: "Select the layout width type",
      },
      COLOR: {
        COLOR: "Color",
        WHITE_COLOR: "White color",
        PRIMARY_COLOR: "Primary color",
        SECONDARY_COLOR: "Secondary color",
        SUCCESS_COLOR: "Success color",
        INFO_COLOR: "Info color",
        WARNING_COLOR: "Warning color",
        DANGER_COLOR: "Danger color",
        LIGHT_COLOR: "Light color",
        DARK_COLOR: "Dark color",
        COLOR_TIP: "Select your color",
      },
    },
    USERS: {
      USERS_LIST: "Users list",
      NEW_USER: "New user",
      FILTER_BY_STATUS: "Filter by status",
      SEARCH_IN_ALL_FIELDS: "Search in all fields",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      FILTER: "Filter",
      BY_STATUS: " by status",
      FILTER_BY_TYPE: "Filter by type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      BY_TYPE: " by type",
      SEARCH: "Search ",
      IN_ALL_FIELDS: "in all fields",
      SELECTED_RECORDS_COUNT: "Selected records count:",
      DELETE_ALL: "Delete all",
      FETCH_SELECTED: "Fetch selected",
      UPDATE_STATUS: "Update status",
      USER: "User",
      USERNAME: "UserName",
      STATUS: "Status",
      ACTIONS: "Actions",
      INACTIVE: "Inactive",
    },
    EDIT_USER: {
      EDIT: "Edit",
      CREATE: "Create",
      BACK: "Back",
      RESET: "Reset",
      BASIC_INFO: "Basic info",
      PROFILE_INFO: "Profile info",
      ADDRESS_INFO: "Address info",
      ENTER_USERNAME: "Enter username",
      ENTER_USERNAME_TIP: "Please enter your username",
      USERNAME_WAS_ENTERED_CORRECT: "Username was entered correct",
      USERNAME_IS_REQUIRED: "Username is required",
      USERNAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Username should have at least 3 symbols",
      USERNAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Username should have maximum 100 symbols",
      ENTER_CPF_CNPJ: "Enter CPF/CNPJ",
      ENTER_CPF_CNPJ_TIP: "Please enter with CPF/CNPJ",
      CPF_CNPJ_WAS_ENTERED_CORRECT: "CPF/CNPJ was entered correct",
      CPF_CNPJ_IS_REQUIRED: "CPF/CNPJ is required",
      CPF_CNPJ_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "CPF/CNPJ should have at least 3 symbols",
      CPF_CNPJ_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "CPF/CNPJ should have maximum 100 symbols",
      SELECT_PLAN: "Select plan",
      SELECT_PLAN_TIP: "Please select a plan",
      SELECT_ACTIVE: "Select active",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      SELECT_ACTIVE_TIP: "Please select active",
      SELECT_ENABLED: "Select enabled",
      ENABLED: "Enabled",
      DISABLED: "Disabled",
      SELECT_ENABLED_TIP: "Please select enabled",
      SELECT_CREDENTIALS_EXPIRED: "Selecy expired credentials",
      SELECT_CREDENTIALS_EXPIRED_TIP: "Please select expired credentials",
      SELECT_EXPIRED: "Select expired",
      SELECT_EXPIRED_TIP: "Please select expired",
      SELECT_LOCKED: "Select locked",
      SELECT_LOCKED_TIP: "Please select locked",
      CANCEL: "Cancel",
      SAVE: "Save",
    },
    USER_PROFILE_EDIT: {
      DATE_OF_BIRTH: "Date of birth",
      DATE_OF_BIRTH_WAS_ENTERED_CORRECT: "Date of birth was entered correct",
      DATE_OF_BIRTH_TIP: "Please enter date of birth in 'dd/mm/yyyy' format",
      SELECT_GENDER: "Select gender",
      FEMALE: "Female",
      MALE: "Male",
      SELECT_GENDER_TIP: "Please select gender",
      ENTER_REAL_NAME: "Enter real name",
      ENTER_REAL_NAME_TIP: "Please enter real name",
      ENTER_REAL_NAME_WAS_ENTERED_CORRECT: "Realname was entered correct",
      ENTER_REAL_NAME_IS_REQUIRED: "Realname is required",
      ENTER_REAL_NAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Realname should have at least 3 symbols",
      ENTER_REAL_NAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Realname should have maximum 100 symbols",
      ENTER_EMAIL: "Enter email",
      ENTER_EMAIL_TIP: "Please enter email",
      ENTER_EMAIL_WAS_ENTERED_CORRECT: "Email was entered correct",
      ENTER_EMAIL_IS_REQUIRED: "Email is required",
      ENTER_EMAIL_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Email should have at least 3 symbols",
      ENTER_EMAIL_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Email should have maximum 100 symbols",
      ENTER_PHONE_NUMBER: " Enter phone number",
      ENTER_PHONE_NUMBER_TIP: "Please enter phone number",
      ENTER_PHONE_NUMBER_WAS_ENTERED_CORRECT:
        "Phone number was entered correct",
      ENTER_PHONE_NUMBER_IS_REQUIRED: "Phone number is required",
      ENTER_PHONE_NUMBER_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Phone number should have at least 3 symbols",
      ENTER_PHONE_NUMBER_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Phone number should have maximum 100 symbols",
      ENTER_SITE: "Enter site",
      ENTER_SITE_TIP: "Please enter site",
      ENTER_SITE_WAS_ENTERED_CORRECT: "Site was entered correct",
      ENTER_SITE_IS_REQUIRED: "Site is required",
      ENTER_SITE_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Site should have at least 3 symbols",
      ENTER_SITE_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Site should have maximum 100 symbols",
      CANCEL: "Cancel",
      SAVE: "Save",
    },
    DELETE_USER: {
      USER_DELETE: "User delete",
      USER_IS_DELETING: "User is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER:
        "Are you sure to permanently delete this user?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    DELETE_USERS: {
      USERS_DELETE: "Users delete",
      USERS_IS_DELETING: "Users is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS:
        "Are you sure to permanently delete selected users?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    FETCH_USERS: {
      FETCH_SELECTED_USERS: "Fetch selected users",
      USERNAME: "User name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      PENDING: "Pending",
      CANCEL: "Cancel",
      FETCH: "Fetch",
    },
    UPDATE_USERS: {
      STATUS_UPDATE_FOR_SELECTED_USERS: "Status update for selected users",
      USERNAME: "User name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      PENDING: "Pending",
      CANCEL: "Cancel",
      UPDATE_STATUS: "Update status",
    },
    ASIDE: {
      USER_MANAGEMENT: "User management",
      USERS: "Users",
      ROLES: "Roles",
      SITES: "Sites",
      LEVELS: "Levels",
      PLANS: "Plans",
      USER_MAP: "User map",
      LOGINS: "Logins",
      USER_POWERBI: "User power Bi",
      USER_ACCESS_MANAGEMENT: "User access management",
      COMPANIES: "Companies",
      USERS_ADDRESS: "Users address",
      USER_PROFILE: "User profile",
    },
    USERS_ADDRESS: {
      USERS_ADDRESS_LIST: "Users address list",
      NEW_USER_ADDRESS: "New user address",
      FILTER_BY_STATUS: "Filter by status",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      FILTER: "Filter",
      BY_STATUS: " by status",
      FILTER_BY_TYPE: "Filter by type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      BY_TYPE: " by type",
      SEARCH: "Search",
      IN_ALL_FIELDS: " in all fields",
      SELECTED_RECORDS_COUNT: "Selected records count:",
      DELETE_ALL: "Delete all",
      FETCH_SELECTED: "Fetch selected",
      UPDATE_STATUS: "Update status",
      USER: "User",
      STREET: "Street",
      NUMBER: "Number",
      COMPLEMENT: "Complement",
      NEIGHBORHOOD: "Neighborhood",
      POSTAL_CODE: "Postal code",
      STATE: "State",
      CITY: "City",
      ACTIONS: "Actions",
    },
    EDIT_USER_ADDRESS: {
      EDIT: "Edit",
      CREATE: "Create",
      USER_ADDRESS: "user address",
      SELECT_USER: "Select user",
      PLEASE_SELECT: "Please select",
      USER: "User",
      STREET: "Street",
      STREET_WAS_ENTERED_CORRECT: "Street was entered correct",
      STREET_IS_REQUIRED: "Street is required",
      STREET_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Street should have at least 3 symbols",
      STREET_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Street should have maximum 100 symbols",
      NAME: "Name",
      NAME_WAS_ENTERED_CORRECT: "Name was entered correct",
      NAME_IS_REQUIRED: "Name is required",
      NAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Name should haave at least 3 symbols",
      NAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Name should have maximum 100 symbols",
      NUMBER: "Number",
      NUMBER_WAS_ENTERED_CORRECT: "Number was entered correct",
      NUMBER_IS_REQUIRED: "Number is required",
      NUMBER_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Number should have at least 3 symbols",
      NUMBER_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Number should have maximum 100 symbols",
      COMPLEMENT: "Complement",
      COMPLEMENT_WAS_ENTERED_CORRECT: "Complement was entered correct",
      COMPLEMENT_IS_REQUIRED: "Complement is required",
      COMPLEMENT_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Complement should have at least 3 symbols",
      COMPLEMENT_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Complement should have maximum 100 symbols",
      NEIGHBORHOOD: "Neighborhood",
      NEIGHBORHOOD_WAS_ENTERED_CORRECT: "Neighborhood was entered correct",
      NEIGHBORHOOD_IS_REQUIRED: "Neighborhood is required",
      NEIGHBORHOOD_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Neighborhood should have at least 3 symbols",
      NEIGHBORHOOD_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Neighborhood should have maximum 100 symbols",
      POSTAL_CODE: "Postal code",
      POSTAL_CODE_WAS_ENTERED_CORRECT: "Postal code was entered correct",
      POSTAL_CODE_IS_REQUIRED: "Postal code is required",
      POSTAL_CODE_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Postal code should have at least 3 symbols",
      POSTAL_CODE_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Postal code should have maximum 100 symbols",
      SELECT_STATE: "Select state",
      STATE: "State",
      SELECT_CITY: "Select city",
      CITY: "City",
      CANCEL: "Cancel",
      SAVE: "Save",
    },
    DELETE_USER_ADDRESS: {
      USER_ADDRESS_DELETE: "User address delete",
      USER_ADDRESS_IS_DELETING: "User address is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_ADDRESS:
        "Are you sure to permanently delete this user address?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    DELETE_USERS_ADDRESS: {
      USERS_ADDRESS_DELETE: "Users address delete",
      USERS_ADDRESS_IS_DELETING: "Users address is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_ADDRESS:
        "Are you sure to permanently delete selected users address?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    FETCH_USERS_ADDRESS: {
      FETCH_SELECTED_USERS_ADDRESS: "Fetch selected users address",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      FETCH: "Fetch",
    },
    UPDATE_USERS_ADDRESS: {
      STATUS_UPDATE_FOR_SELECTED_USERS_ADDRESS:
        "Status update for selected users address",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      UPDATE_STATUS: "Update status",
    },
    USER_ACCESS_MANAGEMENT: {
      USER_ACCESS_MANAGEMENT_LIST: "User access management list",
      NEW_USER_ACCESS_MANAGEMENT: "New user access management",
      FILTER_BY_STATUS: "Filter by status",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      FILTER: "Filter",
      BY_STATUS: " by statys",
      FILTER_BY_TYPE: "Filter by type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      BY_TYPE: " by type",
      SEARCH: "Search ",
      IN_ALL_FIELDS: "in all fields",
      SELECTED_RECORDS_COUNT: "Selected records count:",
      DELETE_ALL: "Delete all",
      FETCH_SELECTED: "Fetch selected",
      UPDATE_STATUS: "Update status",
      ID: "ID",
      USER_EMAIL_USERNAME: "User email / User name",
      USER_REALNAME: "User real name",
      ENABLED: "Enabled",
      CREDENTIALS_EXPIRED: "Credentials expired",
      EXPIRED: "Expired",
      LOCKED: "Locked",
      ACCESS_EXP_DATE: "Access exp date",
      DISABLED: "Disabled",
      UNLOCKED: "Unlocked",
      ACTIONS: "Actions",
    },
    EDIT_USER_ACCESS_MANAGEMENT: {
      EDIT: "Edit",
      CREATE: "Create",
      USER_ACCESS_MANAGEMENT: "user access management",
      USER_EMAIL_USERNAME: "User email / User name",
      USER_REALNAME: "User real name",
      SELECT_ENABLED: "Select enabled",
      ENABLED: "Enabled",
      DISABLED: "Disabled",
      PLEASE_SELECT: "Please select",
      SELECT_CREDENTIALS_EXPIRED: "Select credentials expired",
      CREDENTIALS_EXPIRED: " credentials expired",
      EXPIRED: "Expired",
      ACTIVE: "Active",
      SELECT_EXPIRED: "Select expired",
      SELECT_LOCKED: "Select locked",
      UNLOCKED: "Unlocked",
      LOCKED: "Locked",
      ACCESS_EXP_DATE: "Access exp date",
      DD_MM_YYYY: "DD/MM/YYYY",
      ACCESS_EXPIRATION_DATE_WAS_ENTERED_CORRECT:
        "Access expiration date was entered correct",
      PLEASE_ENTER: "Please, enter",
      IN_DD_MM_YYYY_FORMAT: "In DD/MM/YYYY format",
      CANCEL: "Cancel",
      SAVE: "Save",
    },
    DELETE_USER_ACCESS_MANAGEMENT: {
      USER_ACCESS_MANAGEMENT_DELETE: "User access management delete",
      USER_ACCESS_MANAGEMENT_IS_DELETING:
        "User access management is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_ACCESS_MANAGEMENT:
        "Are you sure to permanently delete this user access management?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    DELETE_USERS_ACCESS_MANAGEMENT: {
      USERS_ACCESS_MANAGEMENT_DELETE: "Users access management delete",
      USERS_ACCESS_MANAGEMENT_IS_DELETING:
        "Users access management is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_ACCESS_MANAGEMENT:
        "Are you sure to permanently delete selected users access management?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    FETCH_USERS_ACCESS_MANAGEMENT: {
      FETCH_SELECTED_USERS_ACCESS_MANAGEMENT:
        "Fetch selected users access management",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      FETCH: "Fetch",
    },
    UPDATE_USERS_ACCESS_MANAGEMENT: {
      STATUS_UPDATE_FOR_SELECTED_USERS_ACCESS_MANAGEMENT:
        "Status update for selected users access management",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Supended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      UPDATE_STATUS: "Update status",
    },
    LOGINS: {
      LOGINS_LIST: "Logins list",
      FILTER_BY_STATUS: "Filter by status",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      FILTER: "Filter",
      BY_STATUS: " by statys",
      FILTER_BY_TYPE: "Filter by type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      BY_TYPE: " by type",
      SEARCH: "Search ",
      IN_ALL_FIELDS: "in all fields",
      SELECTED_RECORDS_COUNT: "Selected records count:",
      DELETE_ALL: "Delete all",
      FETCH_SELECTED: "Fetch selected",
      UPDATE_STATUS: "Update status",
      ID: "ID",
      NAME: "Name",
      CREATE_DATE: "Create date",
      IP_ADDRESS: "Ip address",
    },
    USERS_EBI: {
      USERS_EBI_LIST: "Users power BI list",
      NEW_USER_EBI: "New user power BI",
      FILTER_BY_STATUS: "Filter by status",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      FILTER: "Filter",
      BY_STATUS: " by statys",
      FILTER_BY_TYPE: "Filter by type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      BY_TYPE: " by type",
      SEARCH: "Search ",
      IN_ALL_FIELDS: "in all fields",
      SELECTED_RECORDS_COUNT: "Selected records count:",
      DELETE_ALL: "Delete all",
      FETCH_SELECTED: "Fetch selected",
      UPDATE_STATUS: "Update status",
      ID: "ID",
      USER: "User",
      USER_LEVEL: "User level",
      DESCRIPTION: "Description",
      EBI_TYPE: "e-BI type",
      ACTIONS: "Actions",
    },
    EDIT_USER_EBI: {
      EDIT: "Edit",
      CREATE: "Create",
      USER_EBI: "User power BI",
      SELECT_USER: "Select user",
      PLEASE_SELECT: "Please select",
      USER: " user",
      SELECT_USER_LEVEL: "Select user level",
      USER_LEVEL: " user level",
      SELECT_EBI_TYPE: "Select power BI type",
      EBI_TYPE: " power BI type",
      DESCRIPTION: "Description",
      DESCRIPTION_WAS_ENTERED_CORRECT: "Description was entered correct",
      DESCRIPTION_IS_REQUIRED: "Description is required",
      DESCRIPTION_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Description should have at least 3 symbols",
      DESCRIPTION_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Description should have maximum 100 symbols",
      EBI_URL: "e-BI URL",
      EBI_URL_WAS_ENTERED_CORRECT: "e-BI URL was entered correct",
      EBI_URL_IS_REQUIRED: "e-BI URL is required",
      EBI_URL_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "e-BI URL should have at least 3 symbols",
      EBI_URL_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "e-BI URL should have maximum 100 symbols",
      CANCEL: "Cancel",
      SAVE: "Save",
    },
    DELETE_USER_EBI: {
      USER_EBI_DELETE: "User power BI delete",
      USER_EBI_IS_DELETING: "User power BI is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_EBI:
        "Are you sure to permanently delete this user power BI",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    DELETE_USERS_EBI: {
      USERS_EBI_DELETE: "Users power BI delete",
      USERS_EBI_IS_DELETING: "Users power BI is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_EBI:
        "Are you sure to permanently delete selected users power BI?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    FETCH_USERS_EBI: {
      FETCH_SELECTED_USERS_EBI: "Fetch selected users power BI",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      FETCH: "Fetch",
    },
    UPDATE_USERS_EBI: {
      STATUS_UPDATE_FOR_SELECTED_USERS_EBI:
        "Status update for selected users power BI",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      UPDATE_STATUS: "Update status",
    },
    USERS_MAP: {
      USERS_MAP_LIST: "Users map list",
      NEW_USER_MAP: "New user map",
      FILTER_BY_STATUS: "Filter by status",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      FILTER: "Filter",
      BY_STATUS: " by statys",
      FILTER_BY_TYPE: "Filter by type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      BY_TYPE: " by type",
      SEARCH: "Search ",
      IN_ALL_FIELDS: "in all fields",
      SELECTED_RECORDS_COUNT: "Selected records count:",
      DELETE_ALL: "Delete all",
      FETCH_SELECTED: "Fetch selected",
      UPDATE_STATUS: "Update status",
      ID: "ID",
      USER: "User",
      USER_LEVEL: "User level",
      USER_MAP_2D: "User map 2D",
      USER_MAP_3D: "User map 3D",
      ACTIONS: "Actions",
    },
    EDIT_USER_MAP: {
      EDIT: "Edit",
      CREATE: "Create",
      USER_MAP: "user map",
      SELECT_USER: "Select user",
      PLEASE_SELECT: "Please select",
      USER: " user",
      SELECT_USER_LEVEL: "Select user level",
      USER_LEVEL: " user level",
      SELECT_USER_MAP_2D: "Select user map 2D",
      SELECT_USER_MAP_3D: "Select user map 3D",
      USER_MAP_2D: " user map 2D",
      USER_MAP_3D: " user map 3D",
      CANCEL: "Cancel",
      SAVE: "Save",
    },
    DELETE_USER_MAP: {
      USER_MAP_DELETE: "User map delete",
      USER_MAP_IS_DELETING: "User map is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_USER_MAP:
        "Are you sure to permanently delete this user map?",
      CANCEL: "Cancel",
      SAVE: "Save",
    },
    DELETE_USERS_MAP: {
      USERS_MAP_DELETE: "Users map delete",
      USERS_MAP_IS_DELETING: "Users map os deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_USERS_MAP:
        "Are you sure to permanently delete selected users map?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    FETCH_USERS_MAP: {
      FETCH_SELECTED_USERS_MAP: "Fetch selected users map",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      FETCH: "Fetch",
    },
    UPDATE_USERS_MAP: {
      STATUS_UPDATE_FOR_SELECTED_USERS_MAP:
        "Status update for selected users map",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      UPDATE_STATUS: "Update status",
    },
    PLANS: {
      YOU_ALREADY:
        "You already has an aactive subscription, so it is not possible to re-subscribe. If you have any questions about the plans, please contact the sales team.",
      HOME_BUTTON: "Home",
      PLANS_LIST: "Plans list",
      NEW_PLAN: "New Plan",
      FILTER_BY_STATUS: "Filter by status",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      FILTER: "Filter",
      BY_STATUS: " by statys",
      FILTER_BY_TYPE: "Filter by type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      BY_TYPE: " by type",
      SEARCH: "Search ",
      IN_ALL_FIELDS: "in all fields",
      SELECTED_RECORDS_COUNT: "Selected records count:",
      DELETE_ALL: "Delete all",
      FETCH_SELECTED: "Fetch selected",
      UPDATE_STATUS: "Update status",
      NAME: "Name",
      PRICE: "Price",
      ACTIONS: "Actions",
    },
    EDIT_PLAN: {
      EDIT: "Edit",
      CREATE: "Create",
      PLAN: "Plan",
      NAME: "Name",
      NAME_WAS_ENTERED_CORRECT: "Name was entered correct",
      NAME_IS_REQUIRED: "Name is required",
      NAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Name should have at least 3 symbols",
      NAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Name should have maximum 100 symbols",
      PRICE: "Price",
      PRICE_WAS_ENTERED_CORRECT: "Price was entered correct",
      PRICE_IS_REQUIRED: "Price is required",
      PRICE_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Price should have at least 3 symbols",
      PRICE_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Price should have maximum 100 symbols",
      REFERENCE_CODE: "Reference code",
      REFERENCE_CODE_WAS_ENTERED_CORRECT: "Reference code was entered correct",
      REFERENCE_CODE_IS_REQUIRED: "Reference code is required",
      REFERENCE_CODE_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Reference code should have at least 3 symbols",
      REFERENCE_CODE_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Reference code should have maximum 100 symbols",
      ACCESS_DURATION: "Access duration",
      ACCESS_DURATION_WAS_ENTERED_CORRECT:
        "Access duration was entered correct",
      ACCESS_DURATION_IS_REQUIRED: "Access duration is required",
      ACCESS_DURATION_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Access duration should have at least 3 symbols",
      ACCESS_DURATION_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Access duration should have maximum 100 symbols",
      CANCEL: "Cancel",
      SAVE: "Save",
    },
    DELETE_PLAN: {
      PLAN_DELETE: "Plan delete",
      PLAN_IS_DELETING: "Plan is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_PLAN:
        "Are you sure to permanently delete this plan?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    DELETE_PLANS: {
      PLANS_DELETE: "Plans delete",
      PLANS_IS_DELETING: "Plans is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_PLANS:
        "Are you sure to permanently delete selected plans?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    FETCH_PLANS: {
      FETCH_SELECTED_PLANS: "Fetch selected plans",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      FETCH: "Fetch",
    },
    UPDATE_PLANS: {
      STATUS_UPDATE_FOR_SELECTED_PLANS: "Status update for selected plans",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      UPDATE_STATUS: "Update status",
    },
    SITES: {
      SITES_LIST: "Sites list",
      NEW_SITE: "New site",
      FILTER_BY_STATUS: "Filter by status",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      FILTER: "Filter",
      BY_STATUS: " by statys",
      FILTER_BY_TYPE: "Filter by type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      BY_TYPE: " by type",
      SEARCH: "Search ",
      IN_ALL_FIELDS: "in all fields",
      SELECTED_RECORDS_COUNT: "Selected records count:",
      DELETE_ALL: "Delete all",
      FETCH_SELECTED: "Fetch selected",
      UPDATE_STATUS: "Update status",
      NAME: "Name",
      PRIMARY_COLOR: "Primary color",
      SECONDARY_COLOR: "Secondary color",
      ACCENT_COLOR: "Accent color",
      ACTIONS: "Actions",
    },
    DELETE_SITE: {
      SITE_DELETE: "Site delete",
      SITE_IS_DELETING: "Site is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_SITE:
        "Are you sure to permanently delete this site?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    DELETE_SITES: {
      SITES_DELETE: "Sites delete",
      SITES_IS_DELETING: "Sites is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_SITES:
        "Are you sure to permanently delete selected sites?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    FETCH_SITES: {
      FETCH_SELECTED_SITES: "Fetch selected sites",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      FETCH: "Fetch",
    },
    UPDATE_SITES: {
      STATUS_UPDATE_FOR_SELECTED_SITES: "Status update for selected sites",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      UPDATE_STATUS: "Update status",
    },
    LEVELS: {
      LEVELS_LIST: "Levels List",
      NEW_LEVEL: "New level",
      FILTER_BY_STATUS: "Filter by status",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      FILTER: "Filter",
      BY_STATUS: " by statys",
      FILTER_BY_TYPE: "Filter by type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      BY_TYPE: " by type",
      SEARCH: "Search ",
      IN_ALL_FIELDS: "in all fields",
      SELECTED_RECORDS_COUNT: "Selected records count:",
      DELETE_ALL: "Delete all",
      FETCH_SELECTED: "Fetch selected",
      UPDATE_STATUS: "Update status",
      NAME: "Name",
      ACTIONS: "Actions",
    },
    EDIT_LEVEL: {
      EDIT: "Edit",
      CREATE: "Create",
      LEVEL: "level",
      NAME: "Name",
      NAME_WAS_ENTERED_CORRECT: "Name was entered correct",
      NAME_IS_REQUIRED: "Name is required",
      NAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Name should have at least 3 symbols",
      NAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Name should have maximum 100 symbols",
      SELECT_VIEW_MENU: "Select view menu",
      VIEW_MENU: " view menu",
      SELECT_VIEW_ASSETS: "Select view assets",
      VIEW_ASSETS: " view assets",
      SELECT_VIEW_NOTIFICATIONS: "Select view notifications",
      VIEW_NOTIFICATIONS: " view notifications",
      SELECT_VIEW_CHATS: "Select view chats",
      VIEW_CHATS: " view chats",
      SELECT_VIEW_OPPORTUNITIES: "Select view opportunities",
      VIEW_OPPORTUNITIES: " view opportunities",
      SELECT_VIEW_ACTIVE_INFOMAPS: "Select view active infomaps",
      VIEW_ACTIVE_INFOMAPS: " view active infomaps",
      SELECT_VIEW_MAPBOX: "Select view mapbox",
      VIEW_MAPBOX: " view mapbox",
      SELECT_VIEW_EBI: "Select view power BI",
      VIEW_EBI: " view power BI",
      SELECT_VIEW_ECOBOARD: "Select view ecoboard",
      VIEW_ECOBOARD: " view ecoboard",
      SELECT_VIEW_ECOFLOW: "Select view ecoflow",
      VIEW_ECOFLOW: " view ecoflow",
      ENABLED: "Enabled",
      DISABLED: "Disabled",
      PLEASE_SELECT: "Please select",
      CANCEL: "Cancel",
      SAVE: "Save",
    },
    DELETE_LEVEL: {
      LEVEL_DELETE: "Level delete",
      LEVEL_IS_DELETING: "Level is deleting",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_LEVEL:
        "Are you sure to permanently delete this level?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    DELETE_LEVELS: {
      LEVELS_DELETE: "Levels delete",
      LEVELS_IS_DELETING: "Levels is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_LEVELS:
        "Are you sure to permanently delete selected levels?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    FETCH_LEVELS: {
      FETCH_SELECTED_LEVELS: "Fetch selected levels",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      FETCH: "Fetch",
    },
    UPDATE_LEVELS: {
      STATUS_UPDATE_FOR_SELECTED_LEVELS: "Status update for selected levels",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      UPDATE_STATUS: "Update status",
    },
    ROLES: {
      ROLES_LIST: "Roles list",
      NEW_ROLE: "New role",
      FILTER_BY_STATUS: "Filter by status",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      FILTER: "Filter",
      BY_STATUS: " by statys",
      FILTER_BY_TYPE: "Filter by type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      BY_TYPE: " by type",
      SEARCH: "Search ",
      IN_ALL_FIELDS: "in all fields",
      SELECTED_RECORDS_COUNT: "Selected records count:",
      DELETE_ALL: "Delete all",
      FETCH_SELECTED: "Fetch selected",
      UPDATE_STATUS: "Update status",
      NAME: "Name",
      DESCRIPTION: "Description",
      ACTIONS: "Actions",
    },
    DELETE_ROLE: {
      ROLE_DELETE: "Role delete",
      ROLE_IS_DELETING: "Role is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_ROLE:
        "Are you sure to permanently delete this role?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    DELETE_ROLES: {
      ROLES_DELETE: "Roles delete",
      ROLES_IS_DELETING: "Roles is deleting...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_SELECTED_ROLES:
        "Are you sure to permanently delete selected roles?",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    EDIT_ROLE: {
      EDIT: "Edit",
      CREATE: "Create",
      ROLE: "role",
      NAME: "Name",
      NAME_WAS_ENTERED_CORRECT: "Name was entered correct",
      NAME_IS_REQUIRED: "Name is required",
      NAME_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Name should have at least 3 symbols",
      NAME_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Name should have maximum 100 symbols",
      DESCRIPTION: "Description",
      DESCRIPTION_WAS_ENTERED_CORRECT: "Description was entered correct",
      DESCRIPTION_IS_REQUIRED: "Description is required",
      DESCRIPTION_SHOULD_HAVE_AT_LEAST_3_SYMBOLS:
        "Description should have at least 3 symbols",
      DESCRIPTION_SHOULD_HAVE_MAXIMUM_100_SYMBOLS:
        "Description should have maximum 100 symbols",
      CANCEL: "Cancel",
      SAVE: "Save",
    },
    FETCH_ROLES: {
      FETCH_SELECTED_ROLES: "Fetch selected roles",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      FETCH: "Fetch",
    },
    UPDATE_ROLES: {
      STATUS_UPDATE_FOR_SELECTED_ROLES: "Status update for selected roles",
      FIRSTNAME: "First name",
      LASTNAME: "Last name",
      STATUS: "Status",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      PENDING: "Pending",
      CANCEL: "Cancel",
      UPDATE_STATUS: "Update status",
    },
    MAP_LIST: {
      MAPS_LIST: "Map List",
      MAPS: "My Maps",
      WORKSPACES: "My Workspaces",
      NEW_MAP: "New Workspace",
      EDIT_MAP: "Edit Map",
      DELETE_MAP: "Delete Map",
      MAP_NAME: "Map Name",
      CREATE_NEW_MAP: "Create new empty map",
      COPY_MAP: "Create a new map from an existing map",
      SELECT_MAP: "Select a map to copy",
      SAVE: "Save",
      NAME: "Name",
    },
    EDIT_MAP: {
      PITCH: "Pitch",
      BEARING: "Bearing",
      EDIT: "Edit Map",
      BASIC_INFO: "Basic Info",
      LAYERS: "Layers",
      FILTERS: "Filters",
      ORIENTED_SEARCHES: "Oriented Searches",
      NAME: "Name",
      INITIAL_ZOOM: "Initial Zoom",
      SELECT_BASEMAP: "Thumbnail",
      PLEASE_SELECT: "Please select a ",
      BASEMAP: "Basemap",
      CENTER_LATITUDE: "Center Latitude",
      CENTER_LONGITUDE: "Center Longitude",
      ROLE: "Role",
      CANCEL: "Cancel",
      SAVE: "Save",
      BACK: "Back",
      ADD_LAYER: "Add Layer",
      CREATE: "Create Map",
    },
    DELETE_MAP: {
      MAP_DELETE: "Delete map",
      MAP_IS_DELETING: "The map is being deleted...",
      ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_MAP:
        "Are you sure to delete this map?",
    },
    LAYERS: {
      LAYERS_LIST: "List of layers",
      SEARCH: "Search",
      IN_ALL_FIELDS: " in all fields",
      NAME: "Name",
      DISPLAY_NAME: "Display Name",
      CREATED: "Creation Date",
      VERSION: "Version",
      ACTIONS: "Actions",
    },
    CREATE_MAP_LAYER: {
      PUBLISHED_LAYER: "Published Layer",
      CANCEL: "Cancel",
    },
    PLAN: {
      OPENING_INFORMATION: "Opening Informations",
      OPENING_INFORMATION_DESCRIPTION:
        "(Auctions, Technical Notes, Informs, etc)",
      ELETRIC_ENERGY_GENERATORS: "Eletric Energy Generators",
      ELETRIC_ENERGY_GENERATORS_DESCRIPTION:
        "(Power Plant informations provided by ANEEL, ONS, EPE, ANP, etc) ",
      ELETRIC_ENERGY_TRANSPORTATION: "Eletric Energy Transportation",
      ELETRIC_ENERGY_TRANSPORTATION_DESCRIPTION:
        "(Distribution substations/basic network, Average Tension distribution network, complete Basic Network and its expansion studies)",
      MODAL_TRANSPORTATION_INFRASTRUCTURE:
        "Modal Transportation Infrastructures",
      MODAL_TRANSPORTATION_INFRASTRUCTURE_DESCRIPTION:
        "(Airports, Railways, planned and existent Highways, etc)",
      AMBIENTAL_INFORMATION: "Ambiental Informations",
      AMBIENTAL_INFORMATION_DESCRIPTION:
        "(MMA, INCRA, CAR, National Water Agency, Indigenal Lands and Quilombolas, Caverns, Avial Conservation Areas, Geomorphology/Geodiversity, Mining, etc.)",
      ENERGETIC_POTENTIAL: "Energetic Potential",
      ENERGETIC_POTENTIAL_DESCRIPTION:
        "(Solarimetric Atlas and Brazilian Eolic Potential Atlas)",
      CLOSING_BLOCK: "Closing Block",
      CLOSING_BLOCK_DESCRIPTION:
        "(IBGE, Planialtimetric map, updated satellite image, others)",
      START_BUTTON: "Continue",
      TALK_TO_US: "Talk to Us",
      V1: "550,00/month",
      V2: "1215,00/month",
    },
    WATCHED_VIDEO: {
      WATCH_VIDEO: "Watch video",
      NEXT: "Next",
    },
    READ_TERMS: {
      READ_TERMS: "Read terms",
      I_AGREE_TO_THE_TERMS: "I agree to the terms",
      CONTINUE: "Continue",
    },
    EBI: {
      DASHBOARDS_PANEL: "Dashboards Panel",
    },
    LEADS: {
      YES:"YES",
      NO:"NO",
      DISTRIBUTOR: "Distributor",
      CEG: "Has GD",
      DEMAND: "Demand",
      CONSUMPTION_CLASS: "Consumption Class",
      FREE_MARKET: "Free Market",
      CITY: "City",
      DISTRICT: "District",
      TARIFF_GROUP: "Tariff Group",
      MINIMUM: "Minimum",
      MAXIMUM: "Maximum",
      SELECTED_LEAD_VALUE: "Selected lead value",
      SELECTED_LEAD_AMOUNT: "LEADS Selected:",
      ACCOUNT_VALUE: "account value",
      CONSUMPTION: "consumption",
      LEADS_EXPLANATION_DATE:
        "*When buying a lead, it enters a pool where it will be unavailable to other companies for a period of 3 months.",
      NEXT: "Next",
      CANCEL: "Cancel",
      CONFIRM: "Confirm",
      LEADS_QUANTITY: "Quantities of Selected LEADS",
      LEDAS_AVAILABLE: "LEADS available up to",
      CONFIRM_PURCHASE: "Would you like to confirm your purchase",
      FEDERATIVE_UNIT: "Federative unit",
      LEADS_SELECT_QUANTITY: "Select the amount of LEADS",
      CONFIRM_REQUEST: "Confirmed order",
      LEADS_THANKS_MESSAGE: "Datlaz appreciates the trust.",
      CLOSE: "CLOSE",
      ORDER_NUMBER: "Order Number",
      MESSAGE_ERROR_FILL_BOTH: "Please enter a value in both fields.",
      MESSAGE_ERROR_MIN_VALUE_DIFF:
        "The minimum value must be less than the maximum value.",
      AVAILABLE_LEDS_BY_REGION: "LEADS available in this region.*",
      MAXIMUM_LEADS: "*Maximum of 1000 LEADS per order.",
      SEARCH: "Search",
    },
    TUTORIALS: {
      LIST_TUTORIALS: "List tutorials",
    },
    GED: {
      SHORT_NAME: "Documents",
      EXTENDED_NAME: "Electronic Document Management System",
      NAME: "Name",
      SIZE: "Size",
      CREATED: "Created",
      UPDATED: "Updated",
      RENAME: "Rename",
      COPY_LINK: "Copy link",
      DOWNLOAD: "Download",
      DELETE: "Delete",
      ITEMS: "Items",
      UPLOAD_FILE: "Upload file",
      NEW_FOLDER: "New folder",
      ACTIONS: "Actions",
      FOLDER: "Folder",
      FILE: "File",
      CANCEL: "Cancel",
      SAVE: "Save",
      IS_PUBLIC: "Is public?",
      EXTENSION:"Extension",
      GED_DELETE: {
        FOLDER_DELETE: "Delete folder",
        FOLDER_IS_DELETING: "Folder is being deleted...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_FOLDER:
          "Are you sure to permanently delete the selected folder?",
        FILE_DELETE: "Delete file",
        FILE_IS_DELETING: "File is being deleted...",
        ARE_YOU_SURE_TO_PERMANENTLY_DELETE_THIS_FILE:
          "Are you sure to permanently delete the selected file?",
      },
      GED_FILE:{
        UPLOAD_FILE: "Upload file"
      },
      GED_FOLDER:{
        CREATE_FOLDER: "Create folder"
      }
      ,
      EMPTY_FOLDER_MESSAGE:{
        NO_CONTENT: "This folder has no contentment.",
        CREATE_FOLDER: "Create a folder",
        OR: "or",
        FILE_UPLOAD: "upload a file",
        START: "to start.",
      }
    }
  },
};
