import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../datlaz/modules/auth";
import { LoggedUser } from "../../datlaz/modules/auth/_models/logged-user.model";
import Hotjar from "@hotjar/browser";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {

  private loggedUser: LoggedUser;

  constructor(
    private authService: AuthService
  ) {
    this.loggedUser = this.authService.loggedUser;
  }

  identifyUser() {
    if (environment.analytics.identify) {
      window.rudderanalytics.identify(this.loggedUser.ecotxSid);
      if (environment.hotjar.enabled && environment.hotjar.identify) {
        Hotjar.identify(this.loggedUser.ecotxSid, {
          username: this.loggedUser.userName,
          email: this.loggedUser.userEmail,
        });
      }
    }
  }

  trackLogoutMultisession() {
    if (environment.analytics.trackLogout) {
      const eventProperties = {
        u: this.loggedUser.ecotxSid,
        t: Date.now(),
        s: "logout",
      };
      window.rudderanalytics.track("lms", eventProperties);
    }
  }

  trackWarnMultisession() {
    if (environment.analytics.trackLogout) {
      const eventProperties = {
        u: this.loggedUser.ecotxSid,
        t: Date.now(),
        s: "warn",
      };
      window.rudderanalytics.track("lms", eventProperties);
    }
  }

}
