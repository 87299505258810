
import { OrgUser } from '../../../../modules/user-management/_models/org-user.model';
import { Organization } from '../../../../modules/user-management/_models/organization.model';
import { Plan } from '../../../../modules/user-management/_models/plan.model';
import { UserProfile } from '../../../../modules/user-management/_models/user-profile.model';
import { AddressModel } from './address.model';
import { AuthModel } from './auth.model';
import { SocialNetworksModel } from './social-networks.model';

export class UserModel extends AuthModel {
  sid: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  birthday: string;
  gender: string;
  about: string;
  phoneNumber: string;
  readTerms: boolean;
  watchedVideo: boolean;
  cpfCnpj: string;
  avatarFileId: string;
  wallpaperFileId: string;
  lgpdAcceptCommunications: boolean;
  accountSetup: boolean;
  activeSubscription: boolean;

  // Old Fields, need review
  id: number;
  password: string;
  plan: string;
  createDate: Date;
  lastUpdate: Date;
  accessExpirationDate: Date;
  active: boolean;
  enabled: boolean;
  credentialsExpired: boolean;
  credentialsNonExpired: boolean;
  accountNonLocked: boolean;
  expired: boolean;
  locked: boolean;
  superAdmin: boolean;
  wallpaper: any;
  userLevel: any;
  mainUser: any;
  userPlan: Plan;
  avatar: any;
  roles: any;
  userProfile: UserProfile;
  addresses: Array<any>;
  tokenizedCards: Array<any>;
  roleNames: Array<any>;
  pic: string;
  occupation: string;
  phone: string;
  address?: AddressModel;
  socialNetworks?: SocialNetworksModel;
  organizations: Array<Organization>;
  
  // account information
  language: string;
  timeZone: string;
  communication: {
    email: boolean,
    sms: boolean,
    phone: boolean
  };

  // email settings
  emailSettings: {
    emailNotification: boolean,
    sendCopyToPersonalEmail: boolean,
    activityRelatesEmail: {
      youHaveNewNotifications: boolean,
      youAreSentADirectMessage: boolean,
      someoneAddsYouAsAsAConnection: boolean,
      uponNewOrder: boolean,
      newMembershipApproval: boolean,
      memberRegistration: boolean
    },
    updatesFromKeenthemes: {
      newsAboutKeenthemesProductsAndFeatureUpdates: boolean,
      tipsOnGettingMoreOutOfKeen: boolean,
      thingsYouMissedSindeYouLastLoggedIntoKeen: boolean,
      newsAboutMetronicOnPartnerProductsAndOtherServices: boolean,
      tipsOnMetronicBusinessProducts: boolean
    }
  };

  setUser(user: any) {
    this.id = user.id;
    this.username = user.username || '';
    this.password = user.password || '';
    this.email = user.email || '';
    this.pic = user.pic || './assets/media/users/default.jpg';
    this.roles = user.roles || [];
    this.occupation = user.occupation || '';
    this.phone = user.phone || '';
    this.address = user.address;
    this.socialNetworks = user.socialNetworks;
  }
}
