export interface Payload {
  topic: string;
  userSid: string;
  read: boolean;
  type: NotificationWSType;
  icon?: string;
  content: string;
  title?: string;
  action?: WSNotificationAction;
  actionTarget?: string;
}

export enum NotificationWSType {
  TOASTER_SUCCESS = "TOASTER_SUCCESS",
  TOASTER_WARNING = "TOASTER_WARNING",
  TOASTER_ERROR = "TOASTER_ERROR",
  TOASTER = "TOASTER",
  CONTENT_ONLY = "CONTENT_ONLY",
  TITLE_CONTENT = "TITLE_CONTENT",
  IMPORT_PROGRESSION = "IMPORT_PROGRESSION"
}

export enum WSNotificationAction{
  LOGOUT = 'LOGOUT',
  MULTIPLE_SESSIONS_WARN = 'MULTIPLE_SESSIONS_WARN',
  REDIRECT = 'REDIRECT',
  OPEN_LINK = 'OPEN_LINK',
  UPDATE_USER_IMPORT_PROGRESSION = "UPDATE_USER_IMPORT_PROGRESSION"
}
export class WSMessageContent {
  icon?: string;
  content?: string | Object | Payload;
}