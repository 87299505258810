<!-- Modal content-->
<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Sessão Finalizada</h4>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <p>
          Um acesso simultâneo foi realizado utilizando este usuário, portanto esta sessão será encerrada.
        </p>
      </div>
      <div class="row justify-content-center">
        <em class="fa-solid fa-triangle-exclamation"></em>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modal.dismiss()">
      {{ "KEYWORDS.CLOSE" | translate }}
    </button>
  </div>
</div>
