import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { Subscription } from "rxjs";
import { AuthService } from "./datlaz/modules/auth";
import { TranslationService } from "./modules/i18n/translation.service";
import { locale as brLang } from "./modules/i18n/vocabs/br";
import { locale as chLang } from "./modules/i18n/vocabs/ch";
import { locale as deLang } from "./modules/i18n/vocabs/de";
import { locale as enLang } from "./modules/i18n/vocabs/en";
import { locale as esLang } from "./modules/i18n/vocabs/es";
import { locale as frLang } from "./modules/i18n/vocabs/fr";
import { locale as jpLang } from "./modules/i18n/vocabs/jp";
import { SplashScreenService } from "./_metronic/partials/layout/splash-screen/splash-screen.service";
import { TableExtendedService } from "./_metronic/shared/crud-table";
import Hotjar from "@hotjar/browser";
import { environment } from "../environments/environment";

@Component({
  selector: "body[root]",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: TableExtendedService,
    private authService: AuthService,
    protected $gaService: GoogleAnalyticsService
  ) {
    // register translations
    this.translationService.loadTranslations(
      brLang,
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  ngOnInit() {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        this.tableService.setDefaults();

        // get user and verify account setup
        const authSubscription = this.authService
          .getMe()
          .subscribe(async (res) => {
            if (!res.accountSetup) {
              this.router.navigateByUrl("/account-setup");
              this.splashScreenService.hide();
            } else {
              // hide splash screen
              this.splashScreenService.hide();

              // scroll to top on every route change
              window.scrollTo(0, 0);

              this.$gaService.set({ user_id: res.sid });

              // to display back the body content
              setTimeout(() => {
                document.body.classList.add("page-loaded");
              }, 500);
            }
          });
        this.unsubscribe.push(authSubscription);
      }
    });

    this.unsubscribe.push(routerSubscription);

    this.initHotjar();
  }

  private initHotjar() {
    if (environment.hotjar.enabled) {
      Hotjar.init(environment.hotjar.siteId, environment.hotjar.hotjarVersion, {
        debug: environment.hotjar.debug
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
