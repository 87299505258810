import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  KeycloakEvent,
  KeycloakEventType,
  KeycloakService,
} from "keycloak-angular";
import { KeycloakProfile, KeycloakTokenParsed } from "keycloak-js";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { SessionLogoutModalComponent } from "../session-logout-modal/session-logout-modal.component";
import { UserModel } from "../_models/user.model";
import { LoggedUser } from "../_models/logged-user.model";

const API_USERS_URL = `${environment.apiUrl}/core/users`;

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  private unsubscribe: Subscription[] = [];
  private _loggedUser: LoggedUser;

  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  get loggedUser(): LoggedUser {
    return this._loggedUser;
  }

  set loggedUser(loggedUser: LoggedUser) {
    this._loggedUser = loggedUser;
  }

  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService,
    private modalService: NgbModal
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.registerKeycloakEvents();
  }

  public fetchAndSetUser() {
    this.loggedUser = this.createUserByToken(this.getLoggedUserParsedToken());
  }

  private createUserByToken(payload: any): LoggedUser {
    return {
      sid: payload.sid,
      ecotxSid: payload.ecotx_sid,
      loggedDate: new Date(),
      roles: payload.realm_access.roles,
      userName: payload.name,
      userEmail: payload.email,
      appClient: payload.azp,
    };
  }

  public getLoggedUser(): KeycloakTokenParsed | undefined {
    try {
      return this.keycloakService.getKeycloakInstance().tokenParsed;
    } catch (e) {
      return undefined;
    }
  }

  public getLoggedUserParsedToken(): KeycloakTokenParsed | undefined {
    try {
      return this.keycloakService.getKeycloakInstance().tokenParsed;
    } catch (e) {
      return undefined;
    }
  }

  public getLoggedUserAccessToken(): string | undefined {
    try {
      return this.keycloakService.getKeycloakInstance().token;
    } catch (e) {
      return undefined;
    }
  }

  public getNewAccessToken(): Promise<boolean> {
    return this.keycloakService.updateToken(-1);
  }

  public getMe(): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.http.get<UserModel>(`${API_USERS_URL}/me`).pipe(
      map((user: UserModel) => {
        if (user) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  public isLoggedIn(): Promise<boolean> {
    return this.keycloakService.isLoggedIn();
  }

  public loadUserProfile(): Promise<KeycloakProfile> {
    return this.keycloakService.loadUserProfile();
  }

  public login(): void {
    this.keycloakService.login();
  }

  public logout(): void {
    this.keycloakService.logout(window.location.origin);
  }

  public redirectToProfile(): void {
    this.keycloakService.getKeycloakInstance().accountManagement();
  }

  public getRoles(): string[] {
    return this.keycloakService.getUserRoles();
  }

  public registerKeycloakSessionChecker() {
    setInterval(() => {
      if (!this.keycloakService.isTokenExpired()) {
        this.keycloakService
          .getKeycloakInstance()
          .loadUserInfo()
          .then()
          .catch((e) => {
            this.openSessionLogoutModal();
          });
      }
    }, 60000);
  }

  public registerKeycloakEvents() {
    this.keycloakService.keycloakEvents$.subscribe({
      next: (e: KeycloakEvent) => {
        if (e.type === KeycloakEventType.OnTokenExpired) {
          this.keycloakService.updateToken(20);
        }
      },
    });
  }

  private openSessionLogoutModal() {
    const modalRef = this.modalService.open(SessionLogoutModalComponent);
    modalRef.result.then(
      () => {
        window.location.reload();
      },
      () => {
        window.location.reload();
      }
    );
  }
}
