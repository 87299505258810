import { Injectable, OnInit } from "@angular/core";
import { from, Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../datlaz/modules/auth";
import { UserNotificationService } from "../user-management/_services/user-notification.service";
import { CLOSE_ABNORMAL, CLOSE_PROTOCOL_ERROR, SOCKET_OPEN } from "./constants";

@Injectable({
  providedIn: "root",
})
export class WebSocketService implements OnInit {
  private socketsMap = new Map<string, WebSocket>();

  private openSocketSubject = new Subject();

  private closeSocketSubject = new Subject();

  /**
   * Return all previously registered sockets
   */
  initSocket() {
    return from(this.socketsMap.values());
  }

  constructor(
    private authService: AuthService,
    private userNotificationService: UserNotificationService
  ) {}

  ngOnInit(): void {}

  private registerEvents(ws: WebSocket) {
    // Emitted when the connection is closed. code is a numeric value indicating the status code explaining why the connection has been closed. reason is a Buffer containing a human-readable string explaining why the connection has been closed.
    ws.onclose = (ev: CloseEvent) => {
      this.onClose(ev.code, ev.reason);
    };

    // Emitted when an error occurs. Errors may have a .code property, matching one of the string values defined below under WS Error Codes.
    ws.onerror = (ev: Event) => {
      this.onError(ev);
    };

    // Emitted when a message is received. data is the message content. isBinary specifies whether the message is binary or not.
    ws.onmessage = (ev: MessageEvent) => {
      this.onMessage(ev.data);
    };

    // Emitted when the connection is established.
    ws.onopen = (ev: Event) => {
      this.onOpen();
    };
  }

  private onOpen() {
  }

  private onMessage(data: any) {
    this.userNotificationService.processReceivedWsMessage(data);
    this.userNotificationService.updateNewNotification(true);
  }

  private onError(err: Event) {
  }

  private onClose(code: number, reason: string) {
    if (code === CLOSE_PROTOCOL_ERROR || code === CLOSE_ABNORMAL) {
      this.initDefaultWebSocketConnections();
    }
  }

  public sendMessage(ws: WebSocket, msg: any) {
    ws.send(msg);
  }

  public open(topic: string, token: string) {
    this.socketsMap.set(topic, new WebSocket(`${environment.ecotxNotificationsWSS}/${topic}/?Authorization=${token}`));
    this.registerEvents(this.socketsMap.get(topic));
    this.openSocketSubject.next(this.socketsMap.get(topic));

    setInterval(() => {
      this.ping(this.socketsMap.get(topic))
    }, 30000);
  }

  public close(topic) {
    this.closeSocketSubject.next(topic);
  }

  public initDefaultWebSocketConnections() {
    const loggedUserAccessToken = this.authService.getLoggedUserAccessToken();
    this.open("ws-notifications", loggedUserAccessToken);
  }

  public ping(ws: WebSocket) {
    if (ws.readyState === SOCKET_OPEN) {
      ws.send('__ping__');
    }
  }

}
