import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/datlaz/modules/auth/_services/auth.service";


@Component({
  selector: "app-disconnect-modal",
  templateUrl: "./disconnect-modal.component.html",
  styleUrls: ["./disconnect-modal.component.scss"],
})
export class DisconnectModalComponent implements OnInit {
  constructor(public modal: NgbActiveModal,private authService: AuthService,) {}

  ngOnInit(): void {
    setTimeout(() => {
      window.location.reload();
      this.authService.logout();
    }, 30000);
  }
}
