import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { KeycloakAuthorizationService } from "../../../modules/keycloak-authz-angular/services/keycloak-authorization.service";
import { KeycloakAuthzAuthGuard } from "../../../modules/keycloak-authz-angular/services/keycloak-authz-auth-guard";
import { AuthService } from "./_services/auth.service";

@Injectable({ providedIn: "root" })
export class AuthGuard extends KeycloakAuthzAuthGuard {
  constructor(
    protected router: Router,
    protected keycloakAngular: KeycloakService,
    protected keycloakAuth: KeycloakAuthorizationService,
    protected readonly authService: AuthService
  ) {
    super(router, keycloakAngular, keycloakAuth);
  }

  isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!this.authenticated) {
        this.keycloakAngular.login();
        return;
      }

      const requiredPermissions = route.data.permissions;

      if (!requiredPermissions || requiredPermissions.length === 0) {
        return resolve(true);
      } else {
        if (!this.permissions || this.permissions.length === 0) {
          this.router.navigateByUrl("error/404");
          resolve(false);
        }

        let granted: boolean = false;
        for (const requiredPermission of requiredPermissions) {
          if (this.keycloakAuth.checkAuthorization(requiredPermission)) {
            granted = true;
            break;
          }
        }
        if (!granted) this.router.navigateByUrl("error/404");

        this.authService.fetchAndSetUser();

        resolve(granted);
      }
    });
  }
}
