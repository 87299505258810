export const CLOSE_NORMAL = 1000; // Successful operation / regular socket shutdown
export const CLOSE_GOING_AWAY = 1001; // Client is leaving (browser tab closing)
export const CLOSE_PROTOCOL_ERROR = 1002; // Endpoint received a malformed frame
export const CLOSE_UNSUPPORTED = 1003; // Endpoint received an unsupported frame (e.g. binary-only endpoint received text frame)
export const CLOSED_NO_STATUS = 1005; // Expected close status, received none
export const CLOSE_ABNORMAL = 1006; // No close code frame has been received
export const UNSUPPORTED_PAYLOAD = 1007; // Endpoint received inconsistent message (e.g. malformed UTF-8)
export const POLICY_VIOLATION = 1008; // Generic code used for situations other than 1003 and 1009
export const CLOSE_TOO_LARGE = 1009; // Endpoint won't process large frame
export const MANDATORY_EXTENSION = 1010; // Client wanted an extension which server did not negotiate
export const SERVER_ERROR = 1011; // Internal server error while operating
export const SERVICE_RESTART = 1012; // Server/service is restarting
export const TRY_AGAIN_LATER = 1013; // Temporary server condition forced blocking client's request
export const BAD_GATEWAY = 1014; // Server acting as gateway received an invalid response
export const TLS_HANDSHAKE_FAIL = 1015; // Transport Layer Security handshake failure

export const SOCKET_OPEN = 1;