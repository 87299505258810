import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { TranslationModule } from "../../../modules/i18n/translation.module";
import { KeycloakAuthzAngularModule } from "../../../modules/keycloak-authz-angular/keycloak-authz-angular.module";
import { KeycloakAuthorizationService } from "../../../modules/keycloak-authz-angular/services/keycloak-authorization.service";
import { AuthGuard } from "./auth.guard";
import { initializer } from "./keycloak-initializer";
import { SessionLogoutModalComponent } from './session-logout-modal/session-logout-modal.component';
import { AuthService } from "./_services/auth.service";

@NgModule({
  declarations: [
    SessionLogoutModalComponent
  ],
  imports: [
    CommonModule,
    TranslationModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    KeycloakAngularModule,
    KeycloakAuthzAngularModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService, KeycloakAuthorizationService],
    },
    AuthGuard,
    AuthService
  ]
})
export class AuthModule { }
