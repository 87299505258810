import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./datlaz/modules/auth/auth.guard";
import { TranslateModule } from "@ngx-translate/core";
import { environment } from "../environments/environment";

export const routes: Routes = [
  {
    path: "error",
    loadChildren: () =>
      import("./modules/errors/errors.module").then((m) => m.ErrorsModule),
  },
  {
    path: "account-setup",
    loadChildren: () =>
      import("./modules/account-setup/account-setup.module").then((m) => m.AccountSetupModule),
  },
  {
    path: "legacy",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/layout.module").then((m) => m.LayoutModule),
  },
  {
    path: "",
    canActivate: [AuthGuard],
    loadChildren: () => {
      if (environment.legacyLayout) {
        return import("./pages/layout.module").then((m) => m.LayoutModule);
      } else {
        return import("./datlaz/app-datlaz.module").then((m) => m.AppDatlazModule)
      }
    }
  },
  { path: "**", redirectTo: "error/404" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), TranslateModule],
  exports: [RouterModule],
})
export class AppRoutingModule { }
