import {
    Directive, ElementRef, Input, OnInit
} from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakResourcePermissionsCheck } from '../interfaces/keycloak-permissions';
import { KeycloakAuthorizationService } from '../services/keycloak-authorization.service';

@Directive({
    selector: '[enableForKeycloakAuthorization]'
})
export class EnableForKeycloakAuthorizationDirective implements OnInit {

    @Input('enableForKeycloakAuthorization') requiredAuthorization: string;

    constructor(
        private element: ElementRef,
        private keycloakAngular: KeycloakService,
        private authService: KeycloakAuthorizationService
    ) { }

    ngOnInit() {
        let authCheck = <KeycloakResourcePermissionsCheck>{};
        if (this.requiredAuthorization.includes("#")) {
            let authArr = this.requiredAuthorization.split("#");
            authCheck = {
                rsname: authArr[0],
                scope: authArr[1]
            };
        } else {
            authCheck = {
                rsname: this.requiredAuthorization
            };
        }

        this.keycloakAngular.isLoggedIn().then(res => {
            if (!this.authService.checkAuthorization(authCheck)) {
                this.noAuthPresentAction();
            }
        });
    }

    private noAuthPresentAction() {
        this.element.nativeElement.remove();
    }

}
