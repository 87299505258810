import { NgModule } from '@angular/core';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import { environment } from '../environments/environment';

export function createDefaultApollo(
  httpLink: HttpLink
): ApolloClientOptions<any> {
  return {
    link: httpLink.create({
      uri: `${environment.apiUrl}/graphql`,
      withCredentials: true,
      extractFiles: (body) => extractFiles(body, isExtractableFile),
    }),
    cache: new InMemoryCache(),
  };
}

export function createNamedApollo(
  httpLink: HttpLink
): Record<string, ApolloClientOptions<any>> {
  return {
    apigraphql: {
      name: 'apigraphql',
      link: httpLink.create({
        uri: `${environment.apiGraphqLUrl}`,
        withCredentials: true,
        extractFiles: (body) => extractFiles(body, isExtractableFile),
      }),
      cache: new InMemoryCache(),
    },
    userprofile: {
      name: 'userprofile',
      link: httpLink.create({
        uri: `${environment.userProfileApiUrl}/graphql`,
        withCredentials: true,
        extractFiles: (body) => extractFiles(body, isExtractableFile),
      }),
      cache: new InMemoryCache(),
    },
    mapservices: {
      name: 'mapservices',
      link: httpLink.create({
        uri: `${environment.mapServicesUrl}/graphql`,
        withCredentials: true,
        extractFiles: (body) => extractFiles(body, isExtractableFile),
      }),
      cache: new InMemoryCache(),
    },
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      deps: [HttpLink],
      useFactory: createDefaultApollo,
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      deps: [HttpLink],
      useFactory: createNamedApollo,
    },
  ],
})
export class GraphQLModule {}
